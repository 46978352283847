var ENEA = {};

jQuery(document).ready(function($) {
  $.extend(window.ENEA, {
    init: function() {
      //ENEA.func('localStorageSupport');
      ENEA.func("setContrastMourning", ".js-contast");
      ENEA.func("justifyBoxes", ".service-features__elem_inner p");
      ENEA.func("justifyBoxes", ".custom-contact__box");
      ENEA.func(
        "hideMenuColumn",
        "ul.hover-menu.mobile-hidden > li:not(.single-item)"
      );
      ENEA.func("sortJobOffers", "#job-offers-sort");
      ENEA.func("bipFilterCompany", "#bip-filter-company"); 
      ENEA.func("setJobApplyPopup", ".apply");
      ENEA.func("fakturaPopup", ".faktura");
      ENEA.func("setRegionMap");
      ENEA.func("setRegionMapPopup", ".fancybox-region-map");
      ENEA.func("setGoogleMapPopup", ".fancybox-map");
      ENEA.func("setTabsChanger", ".custom-tabs");
      ENEA.func("setCloseCookieAlert", ".cookie-info a.close");
      ENEA.func("setCloseAlerts", "body");
      //ENEA.func("setTooltips", "body");
      ENEA.func("setNewTooltips");
      ENEA.func("setFlexslider", "body");
      ENEA.func("setHomeSlider", "#home-slider .inner");
      ENEA.func("setContactFormSwitcher", "form");
      ENEA.func("setRotateAnimation", ".menu-rotate-animation");
      ENEA.func("setShowMore", "body");
      ENEA.func("setCustomSelects", "body");
      ENEA.func("setPrintPage", "body");
      ENEA.func("setSwitchers", "body");
      ENEA.func("setFontChanger", "body");
      ENEA.func("setFiltersTriangle", "body");
      ENEA.func("setBreakdownMap", "body");
      ENEA.func("setMassiveFailuresMap", "body");
      ENEA.func("setMainNavigationFix", "body");
      ENEA.func("setHoverNavigation", "body");
      ENEA.func("setBipDocuments", "body");
      ENEA.func("setCalcController", "body");
      ENEA.func("setDynamicForm", "body");
      ENEA.func("setReadMore", "body");
      ENEA.func("setChainedSelects", "body");
      ENEA.func("setMobileMenu", "body");
      ENEA.func("setInputFilter", "input.digit-filter");
      ENEA.func("setFormPagination", "button[name^='str']");
      ENEA.func(
        "auctionsPagination",
        ".content-wrapper .auctions-list .tabs .tab-inner table"
      );
      ENEA.func("setOnResize", "body");
      ENEA.func("setMultiformActions", "body");
      ENEA.func("setTabs", ".tabs");
      ENEA.func("setTabs", ".faq-tabs");
      ENEA.func("setAccordion", ".tabs > div .accordion");
      ENEA.func("setAccordion", ".faq-tabs > div .faq-accordion");
      ENEA.func("setMask", "input.zip-code-mask", ["00-000"]);
      ENEA.func("setMainNavToggle", ".header-bottom nav > ul.hover-menu > li'");
      ENEA.func("setDatepicker", "input[datepicker='true']");
      ENEA.func("setValidationRules", "form");
      ENEA.func("setOnLoad", "body");
      ENEA.func("newsletterTermsValidation");
      ENEA.func("localStorageZamowienia");
      ENEA.func("helperFunction");
      ENEA.func("customOfferTabs");
      ENEA.func("setResponsiveTabs", ".faq-tabs:not(.no-accordion)");
      ENEA.func("setResponsiveTabs", ".tabs:not(.no-accordion)");
      ENEA.func("setResponsiveTabs", ".custom-tabs:not(.no-accordion)");
      ENEA.func("zoomImg", ".zoom-layout");
      ENEA.func("setFilterActions", ".search-box");
      ENEA.func(
        "setCustomFormsActions",
        "form[data-form-name='online-custom-agreement']"
      );
      ENEA.func("setCustomFormsActions", "form#ZamowUmoweOnline");
      ENEA.func("contactDataAKT", "#Aktualizacja_danych_klienta");
      ENEA.func("topmenuToggle", ".topmenu__tabs");
      ENEA.func("initCarousel", ".carousel");
      ENEA.func("initShopareaCarousel", ".offer-shoparea__products-carousel");
      ENEA.func("initTestimonialsCarousel", ".carousel-testimonials", [83]);
      ENEA.func("tilesTabs");
      ENEA.func("generalTabs", ".general-tabs");
      ENEA.func("priceTabs", ".container--price-list");
      ENEA.func("searchToggle", ".menu__search-toggle");
      ENEA.func("mobileMenu", ".menu");
      ENEA.func("openFixedForm", ".fixed-form");
      ENEA.func("generalAccordion", ".general-accordion");
      ENEA.func("priceSwitcher", ".offer-price-list");
      ENEA.func("subTabs", ".subtabs");
      ENEA.func("shopareaBaitBanner", 'input[data-shoparea-banner="true"]');
      ENEA.func("slideDownButton", ".offerbanner__slide-down");
      ENEA.func("loteryNIPChecker", ".lot-biz-lot__header-container");
      ENEA.func("loteryNIPCheckerAJAX", "body");
      ENEA.func("loteryNIPTooltip", "body");
      ENEA.func("setDateMask", "body");
      ENEA.func("scrollToProducts", "body");
      ENEA.func("scrollToContactForm", "body");
      ENEA.func("multistepForm", ".multistep-form");
      ENEA.func("shopareaSection", ".shoparea");
      ENEA.func("accordTable");
      ENEA.func("accordTabs");
      ENEA.func("accordPreview");
      ENEA.func("accordNumberChecker");
      ENEA.func("accordTabHeightAdjust");
      ENEA.func("offerbannerCarousel");
      ENEA.func("removeFocusClicks");
      ENEA.func("energyCounterTabs");
      ENEA.func("labelsTextarea");
      ENEA.func("removeButtonForFileInput");
      ENEA.func("productsShowcaseTabs");
      ENEA.func("showFancyVideo", ".fancybox.iframe");
      ENEA.func("showAssetVideo", "a.video");
      ENEA.func("alertLeavingPage", "body");
      ENEA.func("attachmentsList", ".form-file");

      $(window).on("load", function() {
        ENEA.func("setTabs", ".tabs");
      });
    },
    productsShowcaseTabs: function() {
      $(".package-product__img").click(function() {
        $(this)
          .closest(".packages-showcase__products")
          .find(".package-product")
          .removeClass("active");
        $(this)
          .closest(".package-product")
          .addClass("active");
      });

      $(".packages-showcase__products").slick({
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 768,
            settings: {
              autoplay: true
            }
          }
        ]
      });
    },
    showFancyVideo: function(selector) {
      var ytVideo = $(selector);

      ytVideo.on("click", function(e) {
        e.preventDefault();
      });
      ytVideo.fancybox({
        helpers: {
          overlay: {
            locked: false
          }
        },
        aspectRatio: true
      });
    },
    showAssetVideo: function(selector) {
      var videoLink = $(selector);
      var windowWidth = $(window).width();
      if (windowWidth < 650) {
        var iframeWidth = windowWidth;
        var iframeHeight = windowWidth * 0.56 - 38;
      } else {
        var iframeWidth = 560;
        var iframeHeight = 315;
      }
      if (videoLink.attr("href").indexOf("http") > -1) {
        videoLink.addClass("fancybox iframe");
      } else {
        videoLink.fancybox({
          helpers: {
            overlay: {
              locked: false
            }
          },
          width: iframeWidth,
          height: iframeHeight,
          type: "iframe",
          iframe: {
            preload: false
          }
        });
      }
    },
    alertLeavingPage: (function(selector) {
      window.onbeforeunload = function() {
        var chat = $(".cx-webchat");
        var openedChat = chat.find(".NewTextBubble");

        if (
          document.activeElement.href &&
          document.activeElement.href.indexOf("https://www.enea.pl") === -1
        ) {
          if (openedChat.length > 0) {
            return "Czy chcesz opuścić stronę i zakończyć rozmowę z Konsultantem?";
          } else {
            return undefined;
          }
        }
        return undefined;
      };
    })(),
    removeButtonForFileInput: function() {
      var fileInputs = $("#sponsoringb, #sponsoringc").find("input[type=file]");
      var clearInput = function() {
        this.value = "";
      };
      fileInputs.each(function() {
        $(this).on("click", clearInput);
      });

      var inputs = $("#sponsoringb, #sponsoringc").find("input[type=file]");
      inputs.each(function() {
        $(this)
          .closest(".field")
          .append(
            '<button type="button" class="clear-btn clear-btn--hidden">Usuń plik</button>'
          );
      });

      inputs.each(function() {
        $(this).change(function() {
          if ($(this).val() == "") {
            $(this)
              .closest(".field")
              .find(".clear-btn")
              .addClass("clear-btn--hidden");
          } else {
            $(this)
              .closest(".field")
              .find(".clear-btn")
              .removeClass("clear-btn--hidden");
          }
        });
      });

      $("#sponsoringb, #sponsoringc").on("click", ".clear-btn", function() {
        $(this)
          .closest(".field")
          .find('input[type="file"]')
          .val("");
        jQuery.uniform.update();
        $(this).addClass("clear-btn--hidden");
      });
    },
    labelsTextarea: function() {
      // expand labels for textarea fields
      $(".field textarea")
        .closest(".field")
        .find("label")
        .addClass("label--textarea");
    },
    localStorageSupport: (function() {
      try {
        return window.localStorage !== null;
      } catch (e) {
        return false;
      }
    })(),
    energyCounterTabs: function() {
      $(".energy-counter__tab-header-container").on("click", function() {
        $(this)
          .closest(".energy-counter")
          .find(".energy-counter__tab")
          .removeClass("energy-counter__tab--active");
        $(this)
          .closest(".energy-counter__tab")
          .addClass("energy-counter__tab--active");
      });
    },
    accordPreview: function() {
      $(".accord-icon-link--preview").on("click", function(e) {
        e.preventDefault();
        $.fancybox.open($(this).find(".preview-img"), {
          closeBtn: true,
          padding: 0
        });
      });
    },
    offerbannerCarousel: function() {
      var offerbanner = $(".offerbanner__container");
      offerbanner.slick({
        dots: false,
        arrows: false,
        accessibility: true,
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: false,
        adaptiveHeight: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 8000
      });
    },
    accordTabHeightAdjust: function() {
      $(window).on("resize", function() {
        /**
         * This part of code fixes issue, when $('.accord-tabs') element is placed
         * inside other element with property 'display:none' and styles are not set properly.
         * To use this functionality set class 'widget-outer-element-hidden' on hidden element with widget inside.
         */
        var elementsHidden = $(".widget-outer-element-hidden");
        var removeActive = false;
        if (elementsHidden !== null) {
          var display = elementsHidden.css("display");
          if (display === "none") {
            elementsHidden.addClass("active");
            removeActive = true;
          }
        }

        var tabsContainers = $(".accord-tabs");
        tabsContainers.each(function() {
          var windowWidth = window.innerWidth;
          var double = $(this).hasClass("accord-tabs--2");
          // double tabs are always side by side
          if (double || (!double && windowWidth > 600)) {
            var tabsHeaders = $(this).find(
              ".accord-tabs__header > .accord-text"
            );
            var tabsContent = $(this).find(
              ".accord-tabs__tab--active .accord-tabs__content"
            );
            var tabsContentNotActive = $(this).find(
              ".accord-tabs__tab:not(.accord-tabs__tab--active) .accord-tabs__content"
            );
            var maxHeight = 0;
            tabsHeaders.each(function() {
              $(this).css("height", "auto");
            });
            tabsHeaders.each(function() {
              if (maxHeight < $(this)[0].getBoundingClientRect().height) {
                maxHeight = $(this)[0].getBoundingClientRect().height;
              }
            });
            tabsContent.each(function() {
              $(this).css("padding-top", "" + (maxHeight + 70) + "px");
            });
            tabsContentNotActive.each(function() {
              $(this).css("padding-top", "0");
            });
            tabsHeaders.each(function() {
              $(this).css("height", "" + maxHeight + "px");
            });
          } else {
            var tabsHeaders = $(this).find(
              ".accord-tabs__header > .accord-text"
            );
            var tabsContent = $(this).find(
              ".accord-tabs__tab .accord-tabs__content"
            );
            tabsHeaders.each(function() {
              $(this).css("height", "auto");
            });
            tabsContent.each(function() {
              $(this).css("padding-top", "0");
            });
          }
        });

        if (removeActive) {
          elementsHidden.removeClass("active");
        }
      });
    },
    accordNumberChecker: function() {
      $.fn.getCursorPosition = function() {
        var input = this.get(0);
        if (!input) return; // No (input) element found
        if ("selectionStart" in input) {
          // Standard-compliant browsers
          return input.selectionStart;
        } else if (document.selection) {
          // IE
          input.focus();
          var sel = document.selection.createRange();
          var selLen = document.selection.createRange().text.length;
          sel.moveStart("character", -input.value.length);
          return sel.text.length - selLen;
        }
      };
      $.fn.maskDigits = function() {
        $(this).keyup(function() {
          var pos = $(this).getCursorPosition();
          var length = this.value.length;
          this.value = this.value.replace(/[^0-9]/g, "");
          if (pos > 0 && length > this.value.length) {
            pos--;
          }
          setCaretToPos($(this)[0], pos);
        });
      };

      function setCaretToPos(input, pos) {
        setSelectionRange(input, pos, pos);
      }

      function setSelectionRange(input, selectionStart, selectionEnd) {
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
          var range = input.createTextRange();
          range.collapse(true);
          range.moveEnd("character", selectionEnd);
          range.moveStart("character", selectionStart);
          range.select();
        }
      }

      $(".accord-bank-number-checker__input").maskDigits();

      jQuery(".accord-bank-number-checker").on("submit", function(e) {
        e.stopPropagation();
        e.preventDefault();
        var accountNumber = "";
        var target = $(".accord-bank-number-checker").hasClass(
          "accord-bank-number-checker--business"
        )
          ? "business"
          : "home";
        var element = jQuery(".accord-bank-number-checker__verification-box");
        var messageSuccess = jQuery(
          ".accord-bank-number-checker__verification-box .accord-bank-number-checker__verification-box-message--success"
        );
        var messageError = jQuery(
          ".accord-bank-number-checker__verification-box .accord-bank-number-checker__verification-box-message--error"
        );
        var messageInfo = jQuery(
          ".accord-bank-number-checker__verification-box .accord-bank-number-checker__verification-box-message--info"
        );

        accountNumber = $(".accord-bank-number-checker__input")
          .val()
          .replace(/ /g, "");

        if (accountNumber.length === 26) {
          jQuery(".accord-bank-number-checker__loader").addClass(
            "accord-bank-number-checker__loader--active"
          );
          jQuery(".accord-bank-number-checker__input").attr(
            "disabled",
            "disabled"
          );
          jQuery(".accord-bank-number-checker__input-overlay-bg").addClass(
            "disabled"
          );

          messageError.hide();
          messageInfo.hide();
          messageSuccess.hide();
          element
            .removeClass("accord-bank-number-checker__verification-box--error")
            .removeClass(
              "accord-bank-number-checker__verification-box--success"
            );

          jQuery.ajax({
            type: "POST",
            url: "/?controller=okik&action=accountnumber",
            data: "&number=" + accountNumber + "&target=" + target,
            success: function(response) {
              var responseObject = jQuery.parseJSON(response);
              jQuery(".accord-bank-number-checker__loader").removeClass(
                "accord-bank-number-checker__loader--active"
              );
              jQuery(".accord-bank-number-checker__input").removeAttr(
                "disabled"
              );
              jQuery(
                ".accord-bank-number-checker__input-overlay-bg"
              ).removeClass("disabled");

              if (responseObject.state == 1 || responseObject.state == 2) {
                element.slideUp({
                  complete: function() {
                    messageError.hide();
                    messageInfo.hide();
                    messageSuccess.show();
                    element
                      .removeClass(
                        "accord-bank-number-checker__verification-box--error"
                      )
                      .addClass(
                        "accord-bank-number-checker__verification-box--success"
                      );
                    element.slideDown("slow");
                  }
                });
              } else {
                element.slideUp({
                  complete: function() {
                    messageSuccess.hide();
                    messageInfo.hide();
                    messageError.show();
                    element
                      .removeClass(
                        "accord-bank-number-checker__verification-box--success"
                      )
                      .addClass(
                        "accord-bank-number-checker__verification-box--error"
                      );
                    element.slideDown("slow");
                    cookies.set(
                      "acc_bank_num_validation",
                      window.location.pathname,
                      365
                    );
                  }
                });
              }
            }
          });
        } else {
          element.slideUp({
            complete: function() {
              messageSuccess.hide();
              messageError.hide();
              messageInfo.show();
              element
                .removeClass(
                  "accord-bank-number-checker__verification-box--success"
                )
                .addClass(
                  "accord-bank-number-checker__verification-box--error"
                );
              element.slideDown("slow");
            }
          });
        }
        return false;
      });
    },
    accordTabs: function() {
      var tabs = $(".accord-tabs__header-anchor");
      tabs.each(function() {
        var accordeon = $(this).closest(".general-accordion__panel");
        var accordeonId = accordeon.attr("id");
        $(this).attr(
          "href",
          "#" + accordeonId + "_" + $(this).attr("data-tab-id")
        );
      });

      var hash = window.location.hash;
      var tabsAnchors = $(".accord-tabs__header-anchor");
      tabsAnchors.each(function() {
        var tabHash = $(this).attr("href");
        if (tabHash == hash) {
          $(this)
            .closest(".accord-tabs")
            .find(".accord-tabs__tab")
            .removeClass("accord-tabs__tab--active");
          $(this)
            .closest(".accord-tabs__tab")
            .addClass("accord-tabs__tab--active");
          $(".general-accordion__panel").removeClass("open");
          $(this)
            .closest(".general-accordion__panel")
            .addClass("open");
          $(window).trigger("resize");
          $(window).scrollTo(".general-accordion__panel.open", 100);
        }
      });

      $(".anchor-tab").on("click", function(e) {
        var hash = $(this)
          .attr("href")
          .split("#")[1];
        var link = $(this);
        hash = "#" + hash;
        var tabsAnchors = $(".accord-tabs__header-anchor");

        tabsAnchors.each(function() {
          var tabHash = $(this).attr("href");
          if (tabHash == hash) {
            if ($(".accordion-group__expander").text() == "Rozwiń wszystkie") {
              link.closest(".general-accordion__panel").removeClass("open");
            }
            $(this)
              .closest(".accord-tabs")
              .find(".accord-tabs__tab")
              .removeClass("accord-tabs__tab--active");
            $(this)
              .closest(".accord-tabs__tab")
              .addClass("accord-tabs__tab--active");
            $(this)
              .closest(".general-accordion__panel")
              .addClass("open");
            $(window).trigger("resize");
            $(window).scrollTo(
              $(this).closest(".general-accordion__panel.open"),
              100
            );
          }
        });
      });

      $(".accord-tabs__header").on("click", function() {
        $(this)
          .closest(".accord-tabs")
          .find(".accord-tabs__tab")
          .removeClass("accord-tabs__tab--active");
        $(this)
          .closest(".accord-tabs__tab")
          .addClass("accord-tabs__tab--active");
        $(window).trigger("resize");
        var tab = $(this)
          .closest(".accord-tabs__tab")
          .addClass("accord-tabs__tab--active")
          .first();
        if (
          window.outerWidth < 600 &&
          !$(this)
            .closest(".accord-tabs")
            .hasClass("accord-tabs--2")
        ) {
          var top = getCoords(tab[0]).top;
          $(window).scrollTop(top + 100);
        }
      });
    },
    accordTable: function() {
      var tables = $(".accord-table.accord-table--power");
      if (tables) {
        tables.each(function() {
          var rows = $(this).find("tr");
          var header = $(this)
            .find("tr")
            .first();
          var header2 = $(this).find("tr:nth-of-type(2)");
          var header3 = $(this).find("tr:nth-of-type(3)");
          var dataRows = $(this).find("tr:nth-of-type(n+4)");

          var html = '<div class="accord-table-mobile">';

          dataRows.each(function() {
            html += '<div class="accord-table-mobile__item">';
            html += '<table class="accord-table-mobile__table">';
            if (header && header.find("th").first()) {
              html +=
                "<tr><th>" +
                header
                  .find("th")
                  .first()
                  .text() +
                "</th><th></th></tr>";
            }
            if (
              header2 &&
              header3.find("td").first() &&
              $(this)
                .find("td")
                .first()
            ) {
              html +=
                "<tr><td>" +
                header3
                  .find("td")
                  .first()
                  .text() +
                "</td><td>" +
                $(this)
                  .find("td")
                  .first()
                  .text() +
                "</td></tr>";
            }
            html += "</table>";
            html += '<table class="accord-table-mobile__table">';
            if (header && header.find("th:nth-of-type(2)")) {
              html +=
                "<tr><th>" +
                header.find("th:nth-of-type(2)").text() +
                "</th><th></th></tr>";
            }
            if (
              header2 &&
              header3 &&
              header3.find("td:nth-of-type(2)") &&
              header2.find("td:nth-of-type(2)")
            ) {
              html +=
                "<tr><td>" +
                header2.find("td:nth-of-type(2)").text() +
                " " +
                header3.find("td:nth-of-type(2)").text() +
                "</td><td>" +
                $(this)
                  .find("td:nth-of-type(2)")
                  .text() +
                "</td></tr>";
            }
            if (
              header2 &&
              header3 &&
              header3.find("td:nth-of-type(3)") &&
              header2.find("td:nth-of-type(3)")
            ) {
              html +=
                "<tr><td>" +
                header2.find("td:nth-of-type(3)").text() +
                " " +
                header3.find("td:nth-of-type(3)").text() +
                "</td><td>" +
                $(this)
                  .find("td:nth-of-type(3)")
                  .text() +
                "</td></tr>";
            }
            html += "</table>";
            html += '<table class="accord-table-mobile__table">';
            if (header && header.find("th:nth-of-type(3)")) {
              html +=
                "<tr><th>" +
                header.find("th:nth-of-type(3)").text() +
                "</th><th></th></tr>";
            }
            if (
              header2 &&
              header3 &&
              header3.find("td:nth-of-type(4)") &&
              header2.find("td:nth-of-type(4)")
            ) {
              html +=
                "<tr><td>" +
                header2.find("td:nth-of-type(4)").text() +
                " " +
                header3.find("td:nth-of-type(4)").text() +
                "</td><td>" +
                $(this)
                  .find("td:nth-of-type(4)")
                  .text() +
                "</td></tr>";
            }
            if (
              header2 &&
              header3 &&
              header3.find("td:nth-of-type(5)") &&
              header2.find("td:nth-of-type(5)")
            ) {
              html +=
                "<tr><td>" +
                header2.find("td:nth-of-type(5)").text() +
                " " +
                header3.find("td:nth-of-type(5)").text() +
                "</td><td>" +
                $(this)
                  .find("td:nth-of-type(5)")
                  .text() +
                "</td></tr>";
            }
            html += "</table>";
            html += "</div>";
          });
          html += "</div>";
          $(this).after(html);
        });
      }
    },
    justifyBoxes: function(element) {
      if ($(element).length) {
        $(element).matchHeight({
          byRow: true,
          property: "height",
          target: null,
          remove: false
        });
      }
    },
    removeFocusClicks: function() {
      $(".footer__newsletter button").on("mouseup", function() {
        $(this).blur();
      });
      $(".footer__bottom button").on("mouseup", function() {
        $(this).blur();
      });
    },
    shopareaSection: function() {
      $(window).on("resize", function() {
        var shoparea = $(".shoparea");
        var info = shoparea.find(".shoparea__info");
        var product = shoparea.find(".shoparea__product");
        var windowWidth = window.innerWidth;
        if (windowWidth > 830 && windowWidth < 1230) {
          product.css(
            "height",
            "" + info[0].getBoundingClientRect().height + "px"
          );
          shoparea.css(
            "height",
            "" + (info[0].getBoundingClientRect().height + 265) + "px"
          );
        } else {
          product.css("height", "100%");
          shoparea.css("height", "auto");
        }
      });
      $(document).ready(function() {
        $(window).trigger("resize");
      });
    },
    multistepForm: function() {
      if (!$("body").hasClass("backend")) {
        var multistepForm = $(".multistep-form form");
        multistepForm.slick({
          dots: false,
          arrows: false,
          accessibility: false,
          draggable: false,
          infinite: false,
          swipe: false,
          touchMove: false,
          adaptiveHeight: true,
          pauseOnFocus: false,
          pauseOnHover: false
        });

        var multistepFormInputs = multistepForm.find("input,select,textarea");
        multistepFormInputs.on("blur", function(el) {
          $(this).valid();          
        });
        var multistepFormInnerCarousel = $(".simple-form__inputs--carousel");

        var multistepFormFocusable = multistepForm.find(
          "input,select,textarea,a,button"
        );
        multistepFormFocusable.each(function() {
          $(this).attr("tabindex", "-1");
        });
        var fieldsToFocus = $(".multistep-form__step.slick-active").find(
          "input,select,textarea,a,button"
        );
        fieldsToFocus.each(function() {
          $(this).attr("tabindex", "0");
        });

        multistepForm.on("afterChange", function(event, slick, currentSlide) {
          // TODO: add focus transfer after transition
          var fieldsToFocus = $(".multistep-form__step.slick-active").find(
            "input,select,textarea,a,button"
          );
          var titleToFocus = $(".multistep-form__step.slick-active")
            .find(".simple-form__title")
            .attr("tabindex", "-1");
          if (titleToFocus.length > 0) {
            titleToFocus.first().focus();
          } else {
            fieldsToFocus.first().focus();
          }
        });

        $(window).resize(function() {
          multistepForm.slick("resize");
          var w = window.innerWidth;
          if (w > 650) {
            if (multistepFormInnerCarousel.hasClass("slick-initialized")) {
              multistepFormInnerCarousel.slick("unslick");
            }
          } else {
            if (!multistepFormInnerCarousel.hasClass("slick-initialized")) {
              multistepFormInnerCarousel.slick({
                dots: false,
                arrows: true,
                draggable: true,
                infinite: true,
                swipe: true,
                touchMove: true,
                adaptiveHeight: false,
                pauseOnFocus: false,
                pauseOnHover: false
              });
            } else {
              multistepFormInnerCarousel.slick("resize");
            }
          }
        });

        $(window).on("orientationchange", function() {
          multistepForm.slick("resize");
          var w = window.innerWidth;
          if (w > 650) {
            if (multistepFormInnerCarousel.hasClass("slick-initialized")) {
              multistepFormInnerCarousel.slick("unslick");
            }
          } else {
            if (!multistepFormInnerCarousel.hasClass("slick-initialized")) {
              multistepFormInnerCarousel.slick({
                dots: false,
                arrows: true,
                draggable: true,
                infinite: true,
                swipe: true,
                touchMove: true,
                adaptiveHeight: false,
                pauseOnFocus: false,
                pauseOnHover: false
              });
            } else {
              multistepFormInnerCarousel.slick("resize");
            }
          }
        });

        multistepFormInnerCarousel.on("beforeChange", function(
          event,
          slick,
          current,
          next
        ) {
          var radio = $(this).find(
            '.slick-slide[data-slick-index="' + next + '"] input[type="radio"]'
          );
          if (radio.length) {
            radio.trigger("click");
          } else {
            radio = $(this)
              .find('.slick-slide[data-slick-index="' + next + '"]')
              .closest(".simple-form")
              .find('input[type="radio"]');
            radio.prop("checked", false);
          }
          $.uniform.update();
        });

        multistepFormInnerCarousel.on("init", function() {
          var radio = $(this).find('.slick-slide input[type="radio"]');
          if (radio.length) {
            radio.trigger("click");
          } else {
            radio = $(this)
              .find(".slick-slide")
              .closest(".simple-form")
              .find('input[type="radio"]');
            radio.prop("checked", false);
          }
          $.uniform.update();
        });

        $(".multistep-form__button--next").on("click", function() {
          var valid = true;
          if (
            $('input[data-multistepform-name="type"]')
              .closest(".radio")
              .find("span.checked")
              .find("input")
              .attr("data-multistepform-value") == "type1"
          ) {
            var activeOption = $(
              '.simple-form__option[data-simple-form-option="type1"]'
            );
            var options = activeOption
              .closest(".simple-form")
              .find(".simple-form__option")
              .removeClass("simple-form__option--active");
            activeOption.addClass("simple-form__option--active");
          } else {
            var activeOption = $(
              '.simple-form__option[data-simple-form-option="type2"]'
            );
            var options = activeOption
              .closest(".simple-form")
              .find(".simple-form__option")
              .removeClass("simple-form__option--active");
            activeOption.addClass("simple-form__option--active");
          }

          var fieldsToValidate;
          if (
            $(".multistep-form__step.slick-active .simple-form__option--active")
              .length > 0
          ) {
            fieldsToValidate = $(
              ".multistep-form__step.slick-active .simple-form__option--active"
            )
              .find("input, select, textarea")
              .filter("[required]");
          } else {
              if (
                  $(this).is('#compensationWayNextButton')                      
              ) {
                  fieldsToValidate = $(
                      ".multistep-form__step.slick-active .multistep-form__step-container"
                  ).find("input, select, textarea").filter('[required]'); 
              } else {
                  fieldsToValidate = $(
                      ".multistep-form__step.slick-active .simple-form"
                  ).find("input, select, textarea"); 
              }   
          }
          fieldsToValidate.each(function() {
            if (!$(this).valid()) {
              valid = false;
            }
          });

          if (
            $(".multistep-form__step.slick-active .g-recaptcha").length > 0 &&
            grecaptcha.getResponse() == ""
          ) {
            $(".recaptcha-error").html("Proszę uzupełnić captchę.");
            valid = false;
          }

          if (
            valid &&
            !$(this).hasClass("multistep-form__button--submit") &&
              $(this).attr("type") != "submit"
          ) {
            multistepFormFocusable.each(function() {
              $(this).attr("tabindex", "-1");
            });
            multistepForm.slick("slickNext");
            titleToFocus = $(".multistep-form__step.slick-active")
              .find(".simple-form__title")
              .attr("tabindex", "-1");
            var fieldsToFocus = $(".multistep-form__step.slick-active").find(
              "input,select,textarea,a,button"
            );
            document.activeElement.blur();
            fieldsToFocus.each(function() {
              $(this).attr("tabindex", "0");
            });
          } else if (
            valid &&
            ($(this).hasClass("multistep-form__button--submit") ||
              $(this).attr("type") == "submit")
          ) {
            multistepFormFocusable.each(function() {
              $(this).attr("tabindex", "-1");
            });
            var titleToFocus = $(".multistep-form__step.slick-active")
              .find(".simple-form__title")
              .attr("tabindex", "-1");
            var fieldsToFocus = $(".multistep-form__step.slick-active").find(
              "input,select,textarea,a,button"
            );
            document.activeElement.blur();
            fieldsToFocus.each(function() {
              $(this).attr("tabindex", "0");
            });
          }
        });
        $(".multistep-form__button--prev").on("click", function() {
          multistepFormFocusable.each(function() {
            $(this).attr("tabindex", "-1");
          });
          multistepForm.slick("slickPrev");
          var titleToFocus = $(".multistep-form__step.slick-active")
            .find(".simple-form__title")
            .attr("tabindex", "-1");
          var fieldsToFocus = $(".multistep-form__step.slick-active").find(
            "input,select,textarea,a,button"
          );
          document.activeElement.blur();
          fieldsToFocus.each(function() {
            $(this).attr("tabindex", "0");
          });
        });
        $(".multistep-form__button--submit").on("click", function(e) {
          e.preventDefault();
          if (
            $(".multistep-form__step.slick-active .g-recaptcha").length > 0 &&
            grecaptcha.getResponse() == ""
          ) {
            $(".recaptcha-error").html("Proszę uzupełnić captchę.");
          } else {
            multistepForm.submit();
          }
        });
      }
    },
    scrollToProducts: function() {
      $(".scrollToProducts").on("click", function() {
        $(window).scrollTo(".lot-biz__products", 300);
      });
    },
    scrollToContactForm: function() {
      $(".lot-biz__product-button").on("click", function(e) {
        if ($(".lot-biz-umo__form-inner").length > 0) {
          e.preventDefault();
          $(window).scrollTo(".lot-biz-umo__form-inner", 400);
        }
      });
    },
    loteryNIPTooltip: function(show, value) {
      if (show === "show") {
        var top =
          $("input[name='Nip']")[0].getBoundingClientRect().top +
          $(document).scrollTop();
        $(".nip-tooltip")
          .removeClass("hide")
          .css("top", top);
        if (value) {
          $(".nip-tooltip").html(value);
        }
      }
      $(".nip-tooltip .closeTooltip").on("click", function() {
        $(".nip-tooltip").addClass("hide");
      });
    },
    loteryNIPCheckerAJAX: function(show) {
      if (show === "show") {
        if (
          $('.lot-biz-lot__header-container input[name="Nip"]').length > 0 &&
          $('.lot-biz-lot__header-container input[name="Nip"]').valid()
        ) {
          var data = $(
            '.lot-biz-lot__header-container input[name="Nip"]'
          ).val();
          $.ajax({
            url: "/?controller=validate&action=nip-exist",
            method: "POST",
            timeout: 10000,
            data: { nip: data },
            dataType: "text"
          })
            .done(function(response) {
              var res = JSON.parse(response);
              if (res.status === "error") {
                if (res.msg !== undefined && res.msg !== "") {
                  ENEA.loteryNIPTooltip("show", res.msg);
                } else {
                  ENEA.loteryNIPTooltip("show");
                }
              }
            })
            .fail(function(response, textstatus) {});
        }
      }
    },
    loteryNIPChecker: (function() {
      $('.lot-biz-lot__header-container input[name="Nip"]').on(
        "blur",
        function() {
          ENEA.loteryNIPCheckerAJAX("show");
        }
      );
    })(),
    slideDownButton: (function(selector) {
      $(".offerbanner__slide-down").click(function(e) {
        e.preventDefault();
        $(window).scrollTop(660);
      });
    })(),
    dataTypeInput: (function(selector) {
      var today = new Date();
      var dateString = today.toISOString().substring(0, 10);
      $('input[data-type="date"]')
        .attr("type", "date")
        .attr("max", dateString);
    })(),
    priceSwitcher: function(selector) {
      $(selector)
        .find(".radio-field")
        .on("click", function() {
          var checked = $(this)
            .find(".netto-price-type")
            .find(".checked");
          var container = $(this).closest(".offer-price-list");
          if (checked.length === 0) {
            container.find(".price-netto").addClass("hidden");
            container.find(".price-brutto").removeClass("hidden");
          } else {
            container.find(".price-brutto").addClass("hidden");
            container.find(".price-netto").removeClass("hidden");
          }
        });
    },
    topmenuToggle: (function() {
      $(".topmenu__tab--active").click(function() {
        $(".topmenu__tabs").toggleClass("topmenu__tabs--expanded");
      });
      $(".topmenu__tab--active a").click(function(e) {
        e.preventDefault();
      });
    })(),
    searchToggle: (function() {
      $(".menu__search-toggle").click(function() {
        $(".menu__search-form").toggleClass("expanded");
      });
    })(),
    mobileMenu: (function() {
      var active1 = $(".menu__links");
      var active2 = $(".menu__links");
      var activeLevel = 0;
      var active1Title = "";
      $(".noadmin .menu__mobile-toggle").click(function() {
        $(".menu__links").toggleClass("open");
        activeLevel = 0;
      });
      $(".noadmin .menu__mobile-dismiss").click(function() {
        $(".menu__links").removeClass("open");
        $(".submenu").removeClass("visible");
        activeLevel = 0;
        $(".submenu__list").removeClass("visible");
      });
      $(".noadmin .menu__links").click(function() {
        $(".menu__links").removeClass("open");
        $(".submenu").removeClass("visible");
        activeLevel = 0;
        $(".submenu__list").removeClass("visible");
      });
      $(".noadmin .menu__links ul").click(function(e) {
        e.stopPropagation();
      });
      $(".noadmin .menu__mobile-title").click(function() {
        if (activeLevel > 0) {
          if (activeLevel === 2) {
            active2.removeClass("visible");
            $(this).text(active1Title);
          } else if (activeLevel === 1) {
            active1.removeClass("visible");
            $(this)
              .text("Menu")
              .removeClass("active");
          }
          activeLevel--;
        }
      });
      $(".noadmin .menu__link").click(function() {
        active1 = $(this).next(".submenu");
        active1.addClass("visible");
        activeLevel = 1;
        active1Title = $(this).text();
        $(".menu__mobile-title")
          .text(active1Title)
          .addClass("active");
      });

      $(".noadmin .submenu__group-title").click(function() {
        active2 = $(this).next(".submenu__list");
        active2.addClass("visible");
        activeLevel = 2;
        $(".menu__mobile-title").text($(this).text());
      });
    })(),
    initCarousel: (function(selector) {
      $(".carousel").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000
      });
    })(),
    initTestimonialsCarousel: (function(selector) {
      var testimonials = $(".carousel-testimonials");
      testimonials.slick({
        infinite: true,
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 40000000,
        appendArrows: ".carousel__controls",
        appendDots: ".carousel__controls",
        responsive: [
          {
            breakpoint: 790,
            settings: {
              centerPadding: "0px",
              appendArrows: ".carousel-testimonials",
              dots: false
            }
          }
        ]
      });
    })(),
    initShopareaCarousel: (function(selector) {
      $(".offer-shoparea__products-carousel").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000
      });
      $(".shoparea__carousel").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 6000
      });
    })(),
    shopareaBaitBanner: (function(selector) {
      var input = jQuery('input[data-shoparea-banner="true"]');
      input.closest(".field").addClass("shopareaBaitBanner");
      input
        .closest(".field")
        .append("<p>" + input.attr("data-shoparea-banner-message") + "</p>");
    })(),
    slugify: function(str) {
      var _self = this;
      if (typeof _self.__slugifyMap == "undefined") {
        _self.__slugifyMap = {};
        var map = [
          {
            base: " ",
            chars: "\u00A0"
          },
          {
            base: "0",
            chars: "\u07C0"
          },
          {
            base: "A",
            chars:
              "\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F"
          },
          {
            base: "AA",
            chars: "\uA732"
          },
          {
            base: "AE",
            chars: "\u00C6\u01FC\u01E2"
          },
          {
            base: "AO",
            chars: "\uA734"
          },
          {
            base: "AU",
            chars: "\uA736"
          },
          {
            base: "AV",
            chars: "\uA738\uA73A"
          },
          {
            base: "AY",
            chars: "\uA73C"
          },
          {
            base: "B",
            chars: "\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0181"
          },
          {
            base: "C",
            chars: "\uFF43\u24b8\uff23\uA73E\u1E08\u0106"
          },
          {
            base: "D",
            chars:
              "\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018A\u0189\u1D05\uA779"
          },
          {
            base: "Dh",
            chars: "\u00D0"
          },
          {
            base: "DZ",
            chars: "\u01F1\u01C4"
          },
          {
            base: "Dz",
            chars: "\u01F2\u01C5"
          },
          {
            base: "E",
            chars:
              "\u025B\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E\u1D07"
          },
          {
            base: "F",
            chars: "\uA77C\u24BB\uFF26\u1E1E\u0191\uA77B"
          },
          {
            base: "G",
            chars:
              "\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E\u0262"
          },
          {
            base: "H",
            chars:
              "\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D"
          },
          {
            base: "I",
            chars:
              "\u24BE\uFF29\xCC\xCD\xCE\u0128\u012A\u012C\u0130\xCF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197"
          },
          {
            base: "J",
            chars: "\u24BF\uFF2A\u0134\u0248\u0237"
          },
          {
            base: "K",
            chars:
              "\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2"
          },
          {
            base: "L",
            chars:
              "\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780"
          },
          {
            base: "LJ",
            chars: "\u01C7"
          },
          {
            base: "Lj",
            chars: "\u01C8"
          },
          {
            base: "M",
            chars: "\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C\u03FB"
          },
          {
            base: "N",
            chars:
              "\uA7A4\u0220\u24C3\uFF2E\u01F8\u0143\xD1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u019D\uA790\u1D0E"
          },
          {
            base: "NJ",
            chars: "\u01CA"
          },
          {
            base: "Nj",
            chars: "\u01CB"
          },
          {
            base: "O",
            chars:
              "\u24C4\uFF2F\xD2\xD3\xD4\u1ED2\u1ED0\u1ED6\u1ED4\xD5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\xD6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\xD8\u01FE\u0186\u019F\uA74A\uA74C"
          },
          {
            base: "OE",
            chars: "\u0152"
          },
          {
            base: "OI",
            chars: "\u01A2"
          },
          {
            base: "OO",
            chars: "\uA74E"
          },
          {
            base: "OU",
            chars: "\u0222"
          },
          {
            base: "P",
            chars: "\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754"
          },
          {
            base: "Q",
            chars: "\u24C6\uFF31\uA756\uA758\u024A"
          },
          {
            base: "R",
            chars:
              "\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782"
          },
          {
            base: "S",
            chars:
              "\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784"
          },
          {
            base: "T",
            chars:
              "\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786"
          },
          {
            base: "Th",
            chars: "\u00DE"
          },
          {
            base: "TZ",
            chars: "\uA728"
          },
          {
            base: "U",
            chars:
              "\u24CA\uFF35\xD9\xDA\xDB\u0168\u1E78\u016A\u1E7A\u016C\xDC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244"
          },
          {
            base: "V",
            chars: "\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245"
          },
          {
            base: "VY",
            chars: "\uA760"
          },
          {
            base: "W",
            chars: "\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72"
          },
          {
            base: "X",
            chars: "\u24CD\uFF38\u1E8A\u1E8C"
          },
          {
            base: "Y",
            chars:
              "\u24CE\uFF39\u1EF2\xDD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE"
          },
          {
            base: "Z",
            chars:
              "\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762"
          },
          {
            base: "a",
            chars:
              "\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250\u0251"
          },
          {
            base: "aa",
            chars: "\uA733"
          },
          {
            base: "ae",
            chars: "\u00E6\u01FD\u01E3"
          },
          {
            base: "ao",
            chars: "\uA735"
          },
          {
            base: "au",
            chars: "\uA737"
          },
          {
            base: "av",
            chars: "\uA739\uA73B"
          },
          {
            base: "ay",
            chars: "\uA73D"
          },
          {
            base: "b",
            chars: "\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253\u0182"
          },
          {
            base: "c",
            chars:
              "\u24D2\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184\u0043\u0108\u010A\u010C\u00C7\u0187\u023B"
          },
          {
            base: "d",
            chars:
              "\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\u018B\u13E7\u0501\uA7AA"
          },
          {
            base: "dh",
            chars: "\u00F0"
          },
          {
            base: "dz",
            chars: "\u01F3\u01C6"
          },
          {
            base: "e",
            chars:
              "\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u01DD"
          },
          {
            base: "f",
            chars: "\u24D5\uFF46\u1E1F\u0192"
          },
          {
            base: "ff",
            chars: "\uFB00"
          },
          {
            base: "fi",
            chars: "\uFB01"
          },
          {
            base: "fl",
            chars: "\uFB02"
          },
          {
            base: "ffi",
            chars: "\uFB03"
          },
          {
            base: "ffl",
            chars: "\uFB04"
          },
          {
            base: "g",
            chars:
              "\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\uA77F\u1D79"
          },
          {
            base: "h",
            chars:
              "\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265"
          },
          {
            base: "hv",
            chars: "\u0195"
          },
          {
            base: "i",
            chars:
              "\u24D8\uFF49\xEC\xED\xEE\u0129\u012B\u012D\xEF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131"
          },
          {
            base: "j",
            chars: "\u24D9\uFF4A\u0135\u01F0\u0249"
          },
          {
            base: "k",
            chars:
              "\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3"
          },
          {
            base: "l",
            chars:
              "\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747\u026D"
          },
          {
            base: "lj",
            chars: "\u01C9"
          },
          {
            base: "m",
            chars: "\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F"
          },
          {
            base: "n",
            chars:
              "\u24DD\uFF4E\u01F9\u0144\xF1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5\u043B\u0509"
          },
          {
            base: "nj",
            chars: "\u01CC"
          },
          {
            base: "o",
            chars:
              "\u24DE\uFF4F\xF2\xF3\xF4\u1ED3\u1ED1\u1ED7\u1ED5\xF5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\xF6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\xF8\u01FF\uA74B\uA74D\u0275\u0254\u1D11"
          },
          {
            base: "oe",
            chars: "\u0153"
          },
          {
            base: "oi",
            chars: "\u01A3"
          },
          {
            base: "oo",
            chars: "\uA74F"
          },
          {
            base: "ou",
            chars: "\u0223"
          },
          {
            base: "p",
            chars:
              "\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755\u03C1"
          },
          {
            base: "q",
            chars: "\u24E0\uFF51\u024B\uA757\uA759"
          },
          {
            base: "r",
            chars:
              "\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783"
          },
          {
            base: "s",
            chars:
              "\u24E2\uFF53\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B\u0282"
          },
          {
            base: "ss",
            chars: "\xDF"
          },
          {
            base: "t",
            chars:
              "\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787"
          },
          {
            base: "th",
            chars: "\u00FE"
          },
          {
            base: "tz",
            chars: "\uA729"
          },
          {
            base: "u",
            chars:
              "\u24E4\uFF55\xF9\xFA\xFB\u0169\u1E79\u016B\u1E7B\u016D\xFC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289"
          },
          {
            base: "v",
            chars: "\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C"
          },
          {
            base: "vy",
            chars: "\uA761"
          },
          {
            base: "w",
            chars:
              "\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73"
          },
          {
            base: "x",
            chars: "\u24E7\uFF58\u1E8B\u1E8D"
          },
          {
            base: "y",
            chars:
              "\u24E8\uFF59\u1EF3\xFD\u0177\u1EF9\u0233\u1E8F\xFF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF"
          },
          {
            base: "z",
            chars:
              "\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763"
          }
        ];

        for (var i = 0; i < map.length; i += 1) {
          var chars = map[i].chars;
          for (var j = 0; j < chars.length; j += 1) {
            _self.__slugifyMap[chars[j]] = map[i].base;
          }
        }
      }

      return str
        .replace(/[^\u0000-\u007e]/g, function(c) {
          return _self.__slugifyMap[c] || c;
        })
        .toLowerCase()
        .replace(/[^a-z0-9-]/gi, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "");
    },
    auctionsPagination: function(selector) {
      var selector = $(selector);
      if (selector)
        selector.each(function() {
          $(this).bootstrapTable({
            pagination: true
          });
        });
    },
    setContrastMourning: function(selector) {
      if (isMourning === true) {
        if (selector) {
          $(selector).hide();
        }
        $("body").addClass("high-contrast");
      }
      if (selector) {
        $(selector).on("click", function(e) {
          $("body").toggleClass("high-contrast");
        });
      }
    },
    hideMenuColumn: function(selector) {
      if (selector) {
        $(selector).each(function(index, key) {
          if ($(this).find(".nav.short").length === 0) {
            $(this)
              .find(".right")
              .css("right", "213px");

            if ($(this).hasClass("fixed-position")) {
              $(this)
                .find(".submenu-right")
                .css("right", "-426px");
            }
          }
        });
      }
    },
    bipFilterCompany: function(selector) {
      if (selector) {
        $(selector).on("change", function(e) {
          var data = $(this).val();

          var search = window.location.search;
          var queryPartsArr = search.replace("?", "").split("&");
          queryPartsArr.forEach(function(item, index, array) {
            var paramValuePartsArr = item.split("=");
            var param = paramValuePartsArr[0];
            var value = paramValuePartsArr.slice(1).join("=");
            array[index] = [param, value];
          });

          var sortParamExists = false;
          queryPartsArr.forEach(function(item, index, array) {
            if (item[0] === "company") {
              array[index][1] = data;
              sortParamExists = true;
            }
          });
          if (!sortParamExists) {
            queryPartsArr.push(["company", data]);
          }

          var pageParamExists = false;
          queryPartsArr.forEach(function(item, index, array) {
            if (item[0] === "page") {
              array[index][1] = "1";
              pageParamExists = true;
            }
          });
          if (!pageParamExists) {
            queryPartsArr.push(["page", "1"]);
          }

          queryPartsArr.forEach(function(item, index, array) {
            var paramValuePartsArr = item[1].split("=");
            paramValuePartsArr.unshift(item[0]);
            array[index] = paramValuePartsArr.join("=");
          });
          search = "?" + queryPartsArr.join("&");
          window.location.search = search;
        });
      }
    },
    sortJobOffers: function(selector) {
      if (selector) {
        $(selector).on("change", function(e) {
          var data = $(this).val();

          var search = window.location.search;
          var queryPartsArr = search.replace("?", "").split("&");
          queryPartsArr.forEach(function(item, index, array) {
            var paramValuePartsArr = item.split("=");
            var param = paramValuePartsArr[0];
            var value = paramValuePartsArr.slice(1).join("=");
            array[index] = [param, value];
          });

          var sortParamExists = false;
          queryPartsArr.forEach(function(item, index, array) {
            if (item[0] === "sort") {
              array[index][1] = data;
              sortParamExists = true;
            }
          });
          if (!sortParamExists) {
            queryPartsArr.push(["sort", data]);
          }

          var pageParamExists = false;
          queryPartsArr.forEach(function(item, index, array) {
            if (item[0] === "page") {
              array[index][1] = "1";
              pageParamExists = true;
            }
          });
          if (!pageParamExists) {
            queryPartsArr.push(["page", "1"]);
          }

          queryPartsArr.forEach(function(item, index, array) {
            var paramValuePartsArr = item[1].split("=");
            paramValuePartsArr.unshift(item[0]);
            array[index] = paramValuePartsArr.join("=");
          });
          search = "?" + queryPartsArr.join("&");
          window.location.search = search;
        });
      }
    },
    setJobApplyPopup: function(selector) {
      var selector = $(selector);
      if (selector) {
        selector.on("click", function() {
          var fancyboxLink = $(".job-apply-action");
          $.fancybox.open(fancyboxLink, {
            closeBtn: true,
            padding: 0
          });
          return false;
        });
      }
    },
    fakturaPopup: function(selector) {
      var selector = $(selector);
      selector.on("click", function(e) {
        e.preventDefault();
        var fancyboxLink = $("#wzor");
        $.fancybox.open(fancyboxLink, {
          closeBtn: true,
          padding: 0
        });
      });
    },
    setRegionMap: function(action) {
      if (action === "show") {
        $("#vmap").html("");
        $("#vmap").vectorMap({
          map: "pl_mill_en",
          backgroundColor: "#FFFFFF",
          normalizeFunction: "polynomial",
          hoverOpacity: false,
          hoverColor: "#2daae1",
          zoomButtons: false,
          zoomOnScroll: false,
          series: {
            regions: [
              {
                values: {
                  PGE: "#dee0e3",
                  TAURON: "#e8ebee",
                  ENERGA: "#ced1d4",
                  RWE: "#f5f6f7"
                },
                attribute: "fill"
              }
            ]
          },
          markerStyle: {
            initial: {
              fill: "#b3b2b2",
              stroke: false
            },
            hover: {
              stroke: false
            }
          },
          markers: [
            {
              coords: [230, 78],
              name: "Koszalin",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [340, 74],
              name: "Gdańsk",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [426, 115],
              name: "Olsztyn",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [379, 212],
              name: "Płock",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [381, 276],
              name: "Łódź",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [448, 229],
              name: "Warszawa",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [554, 166],
              name: "Białystok",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [523, 322],
              name: "Lublin",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [435, 350],
              name: "Kielce",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [500, 427],
              name: "Rzeszów",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [392, 422],
              name: "Kraków",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [358, 387],
              name: "Katowice",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [253, 331],
              name: "Wrocław",
              style: { fill: "#b3b2b2", stroke: false }
            },
            {
              coords: [152, 131],
              name: "Szczecin",
              style: { fill: "#2daae1", stroke: false }
            },
            {
              coords: [168, 187],
              name: "Gorzów Wlkp.",
              style: { fill: "#2daae1", stroke: false }
            },
            {
              coords: [193, 266],
              name: "Zielona Góra",
              style: { fill: "#2daae1", stroke: false }
            },
            {
              coords: [260, 213],
              name: "Poznań",
              style: { fill: "#2daae1", stroke: false }
            },
            {
              coords: [306, 168],
              name: "Bydgoszcz",
              style: { fill: "#2daae1", stroke: false }
            }
          ],
          onMarkerLabelShow: function(event, label, index) {
            //label.html('');
            label.css({
              background: "#fff",
              color: "#66696d",
              "z-index": "99999"
            });
          },
          onRegionLabelShow: function(event, label, index) {
            //label.html('');
            label.css({
              background: "#fff",
              color: "#66696d",
              "z-index": "99999"
            });
          },
          onMarkerOver: function(event, code) {
            $("body").css({ cursor: "pointer" });
          },
          onMarkerOut: function(event, code) {
            $("body").css({ cursor: "default" });
          },
          onRegionOver: function(event, code) {
            $("body").css({ cursor: "pointer" });
            $(this).on("click", function() {
              var tab = window.location.hash;
              var notEnea = ["PGE", "TAURON", "ENERGA", "RWE"];
              var EneaKeys = {
                ENERGA: 1,
                TAURON: 2,
                PGE: 3,
                RWE: 4
              };

              if (notEnea.indexOf(code) >= 0) {
                $("#enea-area").removeAttr("checked");
                $(".energy-area .radio-field:first-child").removeClass(
                  "is-checked"
                );
                $(".energy-area #uniform-enea-area span").removeClass(
                  "checked"
                );

                $("#not-enea-area").prop("checked", true);
                $(".energy-area .radio-field:nth-child(2)").addClass(
                  "is-checked"
                );
                $(".energy-area #uniform-not-enea-area span").addClass(
                  "checked"
                );

                if ($("#not-customer").length > 0) {
                  $("#not-customer")
                    .click()
                    .click();

                  if ($(".js-company-offer").length < 1) {
                    $(".network-name .custom-select")
                      .prop("selectedIndex", EneaKeys[code])
                      .selectric("refresh");
                  }
                }

                $.fancybox.close();

                data = {};
                data = checkRadio();
                getData(data);
              } else {
                $("#customer")
                  .click()
                  .click();
                $("#enea-area")
                  .click()
                  .click();

                $.fancybox.close();

                data = {};
                data = checkRadio();
                getData(data);
              }
            });
          },
          onRegionOut: function(event, code) {
            $("body").css({ cursor: "default" });
          },
          onRegionClick: function(event, code) {},
          onMarkerClick: function(event, code) {}
        });

        var map = $("#vmap").vectorMap("get", "mapObject");

        var markers = map.markers,
          marker;
        for (marker in markers) {
          var element = markers[marker].element.node;

          var text = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
          );
          text.setAttribute("x", markers[marker].config.coords[0] + 8);
          text.setAttribute("y", markers[marker].config.coords[1]);
          text.textContent = markers[marker].config.name;
          text.setAttribute("font-size", "11");
          text.setAttribute("color", "#66696d");
          text.setAttribute("font-weight", "300");

          // custom text cords
          if (text.textContent === "Zielona Góra") {
            text.setAttribute("x", 153);
            text.setAttribute("y", 256);
          }

          if (text.textContent === "Gorzów Wlkp.") {
            text.setAttribute("x", 141);
            text.setAttribute("y", 204);
          }

          if (text.textContent === "Gdańsk") {
            text.setAttribute("x", 347);
            text.setAttribute("y", 87);
          }

          if (text.textContent === "Olsztyn") {
            text.setAttribute("x", 402);
            text.setAttribute("y", 104);
          }

          if (text.textContent === "Białystok") {
            text.setAttribute("x", 526);
            text.setAttribute("y", 155);
          }

          if (text.textContent === "Warszawa") {
            text.setAttribute("x", 462);
            text.setAttribute("y", 234);
          }

          if (text.textContent === "Wrocław") {
            text.setAttribute("x", 220);
            text.setAttribute("y", 320);
          }

          if (text.textContent === "Katowice") {
            text.setAttribute("x", 327);
            text.setAttribute("y", 377);
          }

          if (text.textContent === "Rzeszów") {
            text.setAttribute("x", 498);
            text.setAttribute("y", 417);
          }

          if (text.textContent === "Poznań") {
            text.setAttribute("x", 245);
            text.setAttribute("y", 229);
          }

          if (text.textContent === "Szczecin") {
            text.setAttribute("x", 137);
            text.setAttribute("y", 146);
          }

          if (text.textContent === "Bydgoszcz") {
            text.setAttribute("x", 267);
            text.setAttribute("y", 159);
          }

          if (element.parentNode) {
            element.parentNode.appendChild(text);
          }
        }
      }
    },
    setRegionMapPopup: function(selector) {
      var selector = $(selector);
      if (selector) {
        $(selector).on("click", function() {
          var fancyboxLink = $(this);

          $.fancybox.open(fancyboxLink, {
            afterShow: function() {
              $("input[data-type=postcode]").focus();
              ENEA.setRegionMap("show");
              var map = $("#vmap").vectorMap("get", "mapObject");
              map.width = "700";
              map.height = "520";
            },
            padding: 0,
            closeBtn: false,
            afterClose: function() {
              $(".jvectormap-label").remove();
            }
          });

          return false;
        });
      }
    },
    setCloseCookieAlert: function(selector) {
      var selector = $(selector);
      if (selector) {
        $(selector).click(function(e) {
          e.preventDefault();
          cookies.set("accessCookie", 1, 365);
          $(".cookie-info").slideUp(function() {
            $(this).remove();
            $("body").removeClass("cookie");
          });
        });
      }
      if (cookies.read("accessCookie") === undefined) {
        var el = $(".cookie-info");
        if (el.length > 0) {
          el.show();
          $("body").addClass("cookie");
        }
      }
    },
    setCloseAlerts: function(selector) {
      var selector = $(selector);
      $(".cookie-info a.close").click(function(e) {
        e.preventDefault();
        cookies.set("accessCookie", 1, 365);

        $(".cookie-info").slideUp(function() {
          $(this).remove();
          $("body").removeClass("cookie");
        });
      });

      $("body").delegate("#alerts a.close", "click ", function(e) {
        e.preventDefault();
        $("#alerts").fadeOut(function() {
          $(this).remove();
        });
      });

      /*$('body').delegate("#alerts", 'click ', function (e) {
             e.preventDefault();
             if ($(e.target).is('div#alerts')) {
             $('#alerts').fadeOut(function () {

             });
             }
             })*/
      selector.on("click", ".calc-details", function(e) {
        e.preventDefault();
        var self = $(this);
        if (self.find(".close").is(":visible")) {
          self.parent().addClass("animation-rotate");
          setTimeout(function() {
            self
              .parents()
              .eq(4)
              .find(".item-left > .details")
              .slideUp(2000);
            self.find(".close").hide();
            self.find(".open").show();
          }, 1000);
        }

        if (self.find(".open").is(":visible")) {
          self.parent().removeClass("animation-rotate");
          setTimeout(function() {
            self
              .parents()
              .eq(3)
              .find(".item-left > .details")
              .slideDown(2000);
            self.find(".close").show();
            self.find(".open").hide();
          }, 1000);
        }
      });
    },
    setTabsChanger: function(selector) {
      var selector = $(selector);
      selector.each(function() {
        var $ul = $(this).find(".custom-tabs-nav");
        var $li = $ul.children("li");
        $li.each(function() {
          var $tabHash = $(this)
            .children("a")
            .attr("href");
          var $trescTaba = $($tabHash);

          if ($(".widget-filters").length <= 0) {
            if ($(this).is(":first-child")) {
              $(this).addClass("active");
              $trescTaba.show();
            } else {
              $trescTaba.hide();
            }
          }
        });

        $li.click(function() {
          $(this)
            .children("a")
            .click();
        });
        $li.children("a").click(function() {
          $li.removeClass("active");
          $li.each(function() {
            $(
              $(this)
                .children("a")
                .attr("href")
            ).hide();
          });
          $(this)
            .parent()
            .addClass("active");
          $($(this).attr("href")).show();
          if (
            $('.orderAgreement[data-href="' + $(this).attr("href") + '"]')
              .length > 0
          ) {
            $(".orderAgreement").hide();
            $(
              '.orderAgreement[data-href="' + $(this).attr("href") + '"]'
            ).show();
          }
          return false;
        });
      });
    },
    setGoogleMapPopup: function(selector) {
      var center;
      if (selector) {
        $(selector).on("click", function() {
          var fancyboxLink = $(this);
          $.fancybox.open(fancyboxLink, {
            padding: 0,
            helpers: {
              overlay: {
                locked: false
              }
            },
            beforeShow: function() {
              center = window.officeMap.getCenter();
              google.maps.event.trigger(window.officeMap, "resize");
              window.officeMap.setCenter(center);

              if (fancyboxLink.data("postcode")) {
                var postcode = fancyboxLink.data("postcode");
                $('.fancybox-inner input[name="zip-code"]').val(postcode);
                //codeAddress(postcode);
              }
            }
          });

          return false;
        });
      }
    },
    setTooltips: function() {
      if ($(".tooltip")[0]) {
        var opts = {
          items: ".tooltip",
          position: { my: "center-0 bottom-20", at: "center-0 bottom-20" },
          show: false,
          hide: false,
          content: function() {
            return $(this).prop("title");
          }
        };
        applyTooltip(opts);
      }
      if ($(".tooltip-center")[0]) {
        var opts = {
          items: ".tooltip-center",
          position: { my: "center-0 bottom-20", at: "center-0 bottom-20" },
          show: false,
          hide: false,
          tooltipClass: "center",
          content: function() {
            return $(this).prop("title");
          }
        };
        applyTooltip(opts);
      }
      function applyTooltip(opts) {
        var isOS = /iPad|iPhone|iPod/.test(navigator.platform);
        var event = isOS ? "touchstart" : "click";
        if (isOS) {
          $(".calc .calc-result>li .item-right .small").css({
            display: "inline-block",
            float: "left",
            width: "auto"
          });
          $(".calc .calc-result>li .item-right .cost-info>div:first-child").css(
            {
              display: "inline-block",
              float: "left",
              width: "auto",
              "margin-right": "5px"
            }
          );
          $(".calc-cost-monthly .left i").css({
            "margin-top": 0,
            "margin-left": "5px"
          });
          $(document).on(event, function(event) {
            var target = $(event.target);
            target.tooltip(opts);
          });
        } else {
          $(document).tooltip(opts);
        }
      }
    },
    setNewTooltips: function() {
      var targets = $('.tooltip, [data-toggle="tooltip"]'),
        target = false,
        tooltip = false,
        title = false;

      targets.bind("mouseenter", function() {
        target = $(this);
        tip = target.attr("title");
        tooltip = $('<div id="tooltip"></div>');

        if (!tip || tip == "") return false;

        target.removeAttr("title");
        tooltip
          .css("opacity", 0)
          .html(tip)
          .appendTo("body");

        var init_tooltip = function() {
          if ($(window).width() < tooltip.outerWidth() * 1.5)
            tooltip.css("max-width", $(window).width() / 2);
          else tooltip.css("max-width", 340);

          var pos_left =
              target.offset().left +
              target.outerWidth() / 2 -
              tooltip.outerWidth() / 2,
            pos_top = target.offset().top - tooltip.outerHeight() - 20;

          if (pos_left < 0) {
            pos_left = target.offset().left + target.outerWidth() / 2 - 20;
            tooltip.addClass("left");
          } else tooltip.removeClass("left");

          if (pos_left + tooltip.outerWidth() > $(window).width()) {
            pos_left =
              target.offset().left -
              tooltip.outerWidth() +
              target.outerWidth() / 2 +
              20;
            tooltip.addClass("right");
          } else tooltip.removeClass("right");

          if (pos_top < 0) {
            var pos_top = target.offset().top + target.outerHeight();
            tooltip.addClass("top");
          } else tooltip.removeClass("top");

          tooltip
            .css({ left: pos_left, top: pos_top })
            .animate({ top: "+=10", opacity: 1 }, 50);
        };

        init_tooltip();
        $(window).resize(init_tooltip);

        var remove_tooltip = function() {
          tooltip.animate({ top: "-=10", opacity: 0 }, 50, function() {
            $(this).remove();
          });

          target.attr("title", tip);
        };

        target.bind("mouseleave", remove_tooltip);
        tooltip.bind("click", remove_tooltip);
        tooltip.bind("#main", remove_tooltip);
      });
    },
    setTabs: function(selector) {
      var selector = $(selector);

      var auctionsTabs = $(".auctions-list .tabs");

      if (selector) {
        selector.tabs();
        auctionsTabs.tabs("option", "active", 0);
      }
    },
    setAccordion: function(selector) {
      if (selector) {
        $(selector).each(function() {
          if (!!$.prototype.accordion) {
            $(this).accordion({
              collapsible: true,
              active: false,
              heightStyle: "content"
            });
          }
        });
      }
    },
    setMask: function(selector, value) {
      if (selector) {
        $(selector).mask(value);
      }
    },
    setDateMask: function() {
      ENEA.setMask('input[data-type="dateNotFuture"]', "0000-00-00");
    },
    setFlexslider: function(selector) {
      //var selector = $(selector);
      $(".post-slider .carousel").flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        slideshow: false,
        itemWidth: 81,
        itemMargin: 14,
        minItems: 3,
        maxItems: 3,
        asNavFor: ".post-slider .slider"
      });
      $(".post-slider .slider").flexslider({
        animation: "fade",
        slideshow: true,
        slideshowSpeed: $(".post-slider .slider").attr("data-speed"),
        controlNav: false,
        directionNav: false,
        sync: ".post-slider .carousel"
      });
      $(".sales-filter-slider.hidden-on-mobile .carousel").flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        directionNav: false,
        slideshow: false,
        itemWidth: 71,
        itemMargin: 8,
        minItems: 4,
        maxItems: 4,
        asNavFor: ".sales-filter-slider.hidden-on-mobile .slider"
      });

      $(".sales-filter-slider.hidden-on-mobile .slider").flexslider({
        animation: "fade",
        slideshow: false,
        controlNav: false,
        animationLoop: false,
        sync: ".sales-filter-slider.hidden-on-mobile .carousel"
      });

      // Sales Filter single slider on mobile
      $(".sales-filter-slider.visible-on-mobile .carousel").flexslider({
        animation: "slide",
        controlNav: false,
        animationLoop: false,
        directionNav: false,
        slideshow: false,
        itemWidth: 71,
        itemMargin: 8,
        minItems: 4,
        maxItems: 4,
        asNavFor: ".sales-filter-slider.visible-on-mobile .slider"
      });

      $(".sales-filter-slider.visible-on-mobile .slider").flexslider({
        animation: "fade",
        slideshow: false,
        controlNav: false,
        animationLoop: false,
        sync: ".sales-filter-slider.visible-on-mobile .carousel"
      });

      // Sales Filter related entries slider
      $(".services-list").flexslider({
        animation: "fade",
        slideshow: false,
        directionNav: false,
        controlNav: true,
        animationLoop: false
      });
      $(".slider .fancybox-image").fancybox();
    },
    setContactFormSwitcher: function(selector) {
      if (selector) {
        var customerType = $('input[name="customer-type"]:checked').val();
        if (customerType == "for-home") {
          $("form .field.for-home").show();
          $("form .field.for-business").hide();
        } else {
          $("form .field.for-business").show();
          $("form .field.for-home").hide();
        }

        $('input[name="customer-type"]').on("change", function() {
          var customerType = $('input[name="customer-type"]:checked').val();
          if (customerType == "for-home") {
            $("form .field.for-home").show();
            $("form .field.for-business").hide();
          } else {
            $("form .field.for-business").show();
            $("form .field.for-home").hide();
          }
        });

        $('input[type="radio"], input[type="checkbox"]').each(function() {
          if ($(this).is(":checked")) {
            $(this)
              .closest(".radio-field, .checkbox-field")
              .addClass("is-checked");
          }
        });

        $('input[type="radio"], input[type="checkbox"]').on(
          "change",
          function() {
            $('input[type="radio"], input[type="checkbox"]').each(function() {
              if ($(this).is(":checked")) {
                $(this)
                  .closest(".radio-field, .checkbox-field")
                  .addClass("is-checked");
              } else {
                $(this)
                  .closest(".radio-field, .checkbox-field")
                  .removeClass("is-checked");
              }
            });
          }
        );
        $('input[type="checkbox"], input[type="radio"], input[type="file"]')
          .filter(function(index) {
            return !$(this).hasClass("uniform-ignore");
          })
          .uniform({
            fileButtonHtml: "Wybierz plik",
            fileDefaultHtml: ""
          });

        $(document).on("click", "label", function() {
          if (
            $(this)
              .parent()
              .hasClass("radio-field") &&
            $(".filters").length == 0
          ) {
            $(this)
              .parent()
              .find(".radio input")
              .click()
              .click();
          }
        });

        $(".companyNameSelect").change(function() {
          $(".companyName").html(
            $(this)
              .find("option:selected")
              .text()
          );
        });
      }
    },
    setOnLoad: function() {
      $(".selectrize").selectric({
        responsive: true,
        disableOnMobile: false
      });

      if (typeof start == "function") {
        $(".network-name .custom-select").selectric({
          onChange: function(element) {
            if (isReady === false) {
              return true;
            }

            data = checkRadio();
            data["otherParams"] = $(element).val();
            getData(data);
          }
        });

        start();

        window.onpopstate = function(event) {
          $(".custom-tabs-nav .active").removeClass("active");

          data = checkRadio();
          getData(data);
        };
      }

      var menuCopy = "",
        isCopied = false,
        isDevSmall = false;

      $(document).on("click", ".open-search", function() {
        $(this).toggleClass("active");
        var search = $("#searchForm");
        search.toggleClass("expanded");
      });

      $(window).on("resize", function() {
        var windowWidth = $(window).width();

        if (windowWidth < 1024) {
          var secondActive = $(
            "aside.left > .widget-multilevel-navigation > ul > li.active"
          );
          //secondActive.prependTo('aside.left > .widget-multilevel-navigation > ul');
          $(
            "aside.left > .widget-multilevel-navigation > ul > li.active > a"
          ).attr("href", "#");

          $(".widget-multilevel-navigation > ul > li.active > a").click(
            function(e) {
              if (opened == true) {
                e.preventDefault();
                $(this)
                  .parents(".widget-multilevel-navigation > ul")
                  .removeClass("opened");
                opened = false;
              } else {
                e.preventDefault();
                $(this)
                  .parents(".widget-multilevel-navigation > ul")
                  .addClass("opened");
                opened = true;
              }
            }
          );
        }

        if (windowWidth < 768) {
          if (isCopied === false) {
            //menuCopy = $(".header-top > .row > ul.main-tabs").clone();
            isCopied = true;
          }
          isDevSmall = true;

          if (!!$.prototype.accordion) {
            $(".footer-box .col-sm-3 .widget-navigation").accordion({
              activate: 2,
              collapsible: true,
              active: false
            });
          }

          var activeElement = $(
            ".header-top > .row > ul.main-tabs > li.active"
          );
          activeElement.prependTo(".header-top > .row > ul.main-tabs");
          $(".header-top > .row > ul.main-tabs > li.active > a").attr(
            "href",
            "#"
          );

          var opened = false;
          activeElement.click(function() {
            if (opened == true) {
              $(this)
                .parent("ul.main-tabs")
                .removeClass("opened");
              opened = false;
            } else {
              $(this)
                .parent("ul.main-tabs")
                .addClass("opened");
              opened = true;
            }
          });
        } else {
          if (isCopied === true && isDevSmall === true) {
            $(".header-top > .row").html(menuCopy);
            isDevSmall = false;
          }
        }
      });
      $(document).ready(function() {
        $(window).trigger("resize");
      });

      $(window).on("load", function() {
        $(".r-tabs").each(function(index) {
          $(this).responsiveTabs("activate", 0);
        });
      });

      var max = 0;
      var section = $(".contact-numbers .widget-helpline .section");

      if (section.length > 0) {
        section.each(function() {
          var elheight = $(this).outerHeight();
          if (elheight > max) {
            max = elheight;
          }
        });
        section.css("height", max + "px");
      }

      max = 0;

      var special = $(".special > .custom-tabs-nav > li");

      if (special.length > 0) {
        $(".special > .custom-tabs-nav > li").each(function() {
          if ($(this).innerHeight() > max) {
            max = $(this).innerHeight();
          }
        });

        special
          .css("min-height", max + 10 + "px")
          .css("height", max + 10 + "px")
          .css("max-height", max + 10 + "px");
      }
    },
    setRotateAnimation: function(selector) {
      if (selector) {
        $(document).on("click", selector, function(e) {
          $(".menu-rotate-animation > span.ico").toggleClass("rotate-down");
        });
      }
    },
    setShowMore: function(selector) {
      $(document).on("click", ".js-menu-show-more-action", function(e) {
        e.preventDefault();

        $(this).hide();
        $(".js-menu-show-less-action").show();

        $(".js-menu-show-more").show("slow", "linear");
        $(".js-menu-show-more > a").animate(
          {
            opacity: 1
          },
          2000,
          function() {
            // Animation complete.
          }
        );
      });

      $(document).on("click", ".js-menu-show-less-action", function(e) {
        e.preventDefault();
        $(this).hide();
        $(".js-menu-show-more-action").show();

        $(".js-menu-show-more > a").animate(
          {
            opacity: 0
          },
          500,
          function() {
            // Animation complete.
          }
        );
        $(".js-menu-show-more").hide("slow", "linear");
      });
    },
    setHomeSlider: function(selector) {
      if (selector) {
        $(selector).flexslider({
          animation: "fade",
          slideshow: true,
          slideshowSpeed: 7000,
          directionNav: false
        });
      }
    },
    setCustomSelects: function() {
      var contactFormSelect = $("form.contact-form select"),
        mapFormSelect = $("form.contact-form select"),
        customSelect = $("select.custom-select"),
        langSelect = $("select.lang-select"),
        simpleFormSelect = $(".simple-form select.simple-form__selectric");

      if (contactFormSelect.length > 0) {
        contactFormSelect.selectric({
          onClose: function() {
            var field = $(this).closest("form");
            field.validate().element($(this));
          },
          disableOnMobile: false,
          responsive: true
        });
      }

      if (mapFormSelect.length > 0) {
        mapFormSelect.selectric({
          disableOnMobile: false,
          responsive: true
        });
      }

      if (simpleFormSelect.length > 0) {
        simpleFormSelect.selectric({
          disableOnMobile: false,
          response: true
        });
      }

      if (customSelect.length > 0) {
        customSelect.selectric({
          disableOnMobile: false,
          responsive: true
        });
        $(".selectric-custom-select").on("selectric-change", function(
          event,
          element,
          selectric
        ) {
          $(event.target).val(event.target.value);
          $(event.target).trigger("change");
        });
      }

      if (langSelect.length > 0) {
        langSelect.selectric({
          disableOnMobile: false,
          responsive: true,
          onInit: function() {
            var customClass =
              "ico-" +
              $("select.lang-select")
                .val()
                .toLowerCase();
            var label = $("select.lang-select")
              .closest(".selectricWrapper")
              .find(".label");
            label.addClass(customClass);
          },
          optionsItemBuilder: function(itemData, element, index) {
            return (
              '<i class="ico-' +
              itemData.text.toLowerCase() +
              '"></i>' +
              itemData.text
            );
          },
          onChange: function(element) {
            var customClass =
              "ico-" +
              $("select.lang-select")
                .closest(".selectricWrapper")
                .find("li.selected")
                .text()
                .toLowerCase();
            var label = $("select.lang-select")
              .closest(".selectricWrapper")
              .find(".label");
            label.attr("class", "label");
            label.addClass(customClass);
            window.location.href = "/" + $(element).val();
          }
        });
      }
      if ($(".search-box").length) {
        $(".search-box select").selectric({
          disableOnMobile: false,
          responsive: true
        });
      }
    },
    setSearchController: function() {
      var searchPagination = $("#search-paging"),
        searchQuery = $("#query");

      if (searchQuery.length > 0) {
        searchQuery.autocomplete("/plugin/SearchPhp/frontend/autocomplete/", {
          minChars: 3,
          onItemSelect: function(event, ui) {
            $("#searchForm").submit();
          }
        });
      }

      if (searchPagination.length > 0) {
        var x = searchPagination.find("li").children("a.active");
        x.parent().addClass("active");
      }
    },
    setValidationRules: function(selector) {
      var selector = selector;
      if (selector) {
        if ($(".ext-strict").length > 0) {
          return false;
        }

        // create main validators container
        var formValidators = {};

        // get all form id's on a page, push to array
        var forms = [];
        $("form").each(function(id, form) {
          if ($(form).attr("id") !== undefined) {
            forms.push($(form).attr("id"));
          }
        });

        // use id's, to loop through inputs for each form
        for (var i = 0; i < forms.length; i++) {
          // create form selector
          var selector = "#" + forms[i];

          // create rules object for rules and messages
          var rulesObj = { debug: false, rules: {}, messages: {} };

          // print debug info
          if (rulesObj.debug === true) {
          }

          // get all inputs in a form and parse them for rules
          $(selector + " *")
            .filter("input, select, textarea")
            .each(function(index, elem) {
              // skip all inputs without a name
              if ($(elem).attr("name") !== undefined) {
                // create easy shortcut for input name
                var name = $(elem).attr("name");

                // print debug info
                if (rulesObj.debug === true) {
                }

                // BASIC SETUP
                // basic setup, create rules and messages objects
                if (rulesObj.rules[name] === undefined) {
                  rulesObj.rules[name] = {};
                  rulesObj.messages[name] = {};
                }

                if ($("#Aktualizacja_danych_klienta").length > 0) {
                  if ($(elem).attr("name") === "Telefon") {
                    $('input[name="Telefon"]')
                      .parent()
                      .find("label")
                      .prepend('<span class="asterix">** </span>');
                    rulesObj.messages["Telefon"].required =
                      "To pole jest wymagane opcjonalnie.";
                    rulesObj.rules["Telefon"].required = function(element) {
                      if (
                        $('input[name="Telefon2"]').val() === "" ||
                        $('input[name="Telefon2"]').val() === undefined
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    };
                  }

                  if ($(elem).attr("name") === "Telefon2") {
                    $('input[name="Telefon2"]')
                      .parent()
                      .find("label")
                      .prepend('<span class="asterix">** </span>');
                    rulesObj.messages["Telefon2"].required =
                      "To pole jest wymagane.";
                    rulesObj.rules["Telefon2"].required = function(element) {
                      if (
                        $('input[name="Telefon"]').val() === "" ||
                        $('input[name="Telefon"]').val() === undefined
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    };
                  }
                }

                // VALIDATION RULES & MESSAGES BASED ON RULES
                // required field default rule
                if ($(elem).attr("required") !== undefined) {
                  rulesObj.rules[name].required = true;
                  rulesObj.messages[name].required =
                    $(elem).attr("msg-req") !== undefined
                      ? $(elem).attr("msg-req")
                      : "To pole jest wymagane.";
                }

                // alnum - alphanumeric
                if ($(elem).attr("data-type") === "alnum") {
                  rulesObj.rules[name].pattern = /^[a-zA-Z0-9]+$/;
                  rulesObj.messages[name].pattern =
                    "Możesz używać znaków alfabetu oraz cyfr.";
                }

                // alpha - alphabetic
                if ($(elem).attr("data-type") === "alpha") {
                  rulesObj.rules[name].pattern = /^[a-zA-Z]+$/;
                  rulesObj.messages[name].pattern =
                    "Wprowadź wyłącznie litery.";
                }

                if ($(elem).attr("data-type") === "dateNotFuture") {
                  rulesObj.rules[name].pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
                  rulesObj.messages[name].pattern =
                    "Wprowadź datę w formacie rrrr-mm-dd";
                  rulesObj.rules[name].date = true;
                  rulesObj.messages[name].date = "Wprowadź poprawną datę";
                  rulesObj.rules[name].dateNotFuture = true;
                  rulesObj.messages[name].dateNotFuture =
                    "Witamy podróżnika w czasie.";
                  rulesObj.rules[name].dateAfter1900 = true;
                  rulesObj.messages[name].dateAfter1900 =
                    "Gratulujemy, że byłeś jednym z pierwszych odbiorców energii elektrycznej w Polsce, ale ta loteria jest dla umów zawartych trochę później.";
                }

                /* NEW VALIDATION DYNAMIC RULES */
                // require_from_group
                if (
                  $(elem).hasClass("js-validation-group-required") &&
                  $(elem).attr("data-js-validation-group-required") !==
                    undefined &&
                  $(elem).attr("data-js-validation-group-required") !== "" &&
                  $(elem).attr("data-js-validation-group-required-amount")
                ) {
                  var n = $(elem).attr(
                    "data-js-validation-group-required-amount"
                  );
                  var group_name = $(elem).attr(
                    "data-js-validation-group-required"
                  );
                  if (
                    !isNaN(parseInt(n)) &&
                    isFinite(n) &&
                    parseInt(n) % 1 === 0
                  ) {
                    var amount = parseInt(n);
                  } else {
                    amount = 1;
                  }
                  rulesObj.rules[name].require_from_group = [
                    amount,
                    '[data-js-validation-group-required="' + group_name + '"]'
                  ];
                }

                // min date
                if (
                  $(elem).hasClass("js-validation-date-min") &&
                  $(elem).attr("data-js-validation-date-min") !== undefined &&
                  $(elem).attr("data-js-validation-date-min") !== ""
                ) {
                  var minDate = $(elem).attr("data-js-validation-date-min");
                  rulesObj.rules[name].date = true;
                  rulesObj.rules[name].date_min = minDate;
                }

                // max date
                if (
                  $(elem).hasClass("js-validation-date-max") &&
                  $(elem).attr("data-js-validation-date-max") !== undefined &&
                  $(elem).attr("data-js-validation-date-max") !== ""
                ) {
                  var maxDate = $(elem).attr("data-js-validation-date-max");
                  rulesObj.rules[name].date = true;
                  rulesObj.rules[name].date_max = maxDate;
                }

                // min date
                if (
                  $(elem).hasClass("js-validation-old-date-min") &&
                  $(elem).attr("data-js-validation-old-date-min") !==
                    undefined &&
                  $(elem).attr("data-js-validation-date-min") !== ""
                ) {
                  var minDate = $(elem).attr("data-js-validation-date-min");
                  rulesObj.rules[name].old_date = true;
                  rulesObj.rules[name].old_date_min = minDate;
                }

                // max date
                if (
                  $(elem).hasClass("js-validation-old-date-max") &&
                  $(elem).attr("data-js-validation-old-date-max") !==
                    undefined &&
                  $(elem).attr("data-js-validation-date-max") !== ""
                ) {
                  var maxDate = $(elem).attr("data-js-validation-date-max");
                  rulesObj.rules[name].old_date = true;
                  rulesObj.rules[name].old_date_max = maxDate;
                }

                // min words
                if (
                  $(elem).hasClass("js-validation-min-words") &&
                  $(elem).attr("data-js-validation-min-words") !== undefined &&
                  $(elem).attr("data-js-validation-min-words") !== ""
                ) {
                  var minWords = $(elem).attr("data-js-validation-min-words");
                  rulesObj.rules[name].min_words = minWords;
                }

                // max words
                if (
                  $(elem).hasClass("js-validation-max-words") &&
                  $(elem).attr("data-js-validation-max-words") !== undefined &&
                  $(elem).attr("data-js-validation-max-words") !== ""
                ) {
                  var maxWords = $(elem).attr("data-js-validation-max-words");
                  rulesObj.rules[name].max_words = maxWords;
                }

                // range words
                if (
                  $(elem).hasClass("js-validation-range-words") &&
                  $(elem).attr("data-js-validation-max-words") !== undefined &&
                  $(elem).attr("data-js-validation-max-words") !== "" &&
                  $(elem).attr("data-js-validation-min-words") !== undefined &&
                  $(elem).attr("data-js-validation-min-words") !== ""
                ) {
                  var maxWords = $(elem).attr("data-js-validation-max-words");
                  var minWords = $(elem).attr("data-js-validation-min-words");
                  rulesObj.rules[name].range_words = [minWords, maxWords];
                }

                // number min
                if (
                  $(elem).hasClass("js-validation-number-min") &&
                  $(elem).attr("data-js-validation-number-min") !== undefined &&
                  $(elem).attr("data-js-validation-number-min") !== ""
                ) {
                  var min = $(elem).attr("data-js-validation-number-min");
                  rulesObj.rules[name].number = true;
                  rulesObj.rules[name].number_min = min;
                }

                // number max
                if (
                  $(elem).hasClass("js-validation-number-max") &&
                  $(elem).attr("data-js-validation-number-max") !== undefined &&
                  $(elem).attr("data-js-validation-number-max") !== ""
                ) {
                  var max = $(elem).attr("data-js-validation-number-max");
                  rulesObj.rules[name].number = true;
                  rulesObj.rules[name].number_max = max;
                }

                // number between
                if (
                  $(elem).hasClass("js-validation-number-between") &&
                  $(elem).attr("data-js-validation-number-max") !== undefined &&
                  $(elem).attr("data-js-validation-number-max") !== "" &&
                  $(elem).attr("data-js-validation-number-min") !== undefined &&
                  $(elem).attr("data-js-validation-number-min") !== ""
                ) {
                  var max = $(elem).attr("data-js-validation-number-max");
                  var min = $(elem).attr("data-js-validation-number-min");
                  rulesObj.rules[name].number = true;
                  rulesObj.rules[name].number_between = [min, max];
                }

                // repeat field
                if (
                  $(elem).hasClass("js-validation-repeat-field") &&
                  $(elem).attr("data-js-validation-repeat-field") !==
                    undefined &&
                  $(elem).attr("data-js-validation-repeat-field") !== ""
                ) {
                  var elemName = $(elem).attr(
                    "data-js-validation-repeat-field"
                  );
                  var form = $(elem).closest("form");
                  var val = form.find('[name="' + elemName + '"]');
                  if (val.length > 0) {
                    rulesObj.rules[name].repeat_field = val.first();
                  }
                  val.on("input", function() {
                    $(elem).valid();
                  });
                }

                // repeat email
                if (
                  $(elem).hasClass("js-validation-repeat-email") &&
                  $(elem).attr("data-js-validation-repeat-email") !==
                    undefined &&
                  $(elem).attr("data-js-validation-repeat-email") !== ""
                ) {
                  var elemName = $(elem).attr(
                    "data-js-validation-repeat-email"
                  );
                  var form = $(elem).closest("form");
                  var val = form.find('[name="' + elemName + '"]');
                  rulesObj.rules[name].email = true;
                  if (val.length > 0) {
                    rulesObj.rules[name].repeat_email = val.first();
                  }
                  val.on("input", function() {
                    $(elem).valid();
                  });
                }

                /* END OD NEW VALIDATION DYNAMIC RULES */

                /* NEW MASKS */

                // date mask
                if ($(elem).hasClass("js-mask-date")) {
                  $(elem).mask("0000-00-00");
                }

                if ($(elem).hasClass("js-mask-old-date")) {
                  $(elem).mask("00.00.0000");
                }

                // postal code mask
                if ($(elem).hasClass("js-mask-postal-code")) {
                  $(elem).mask("00-000");
                }

                // price mask
                if ($(elem).hasClass("js-mask-price")) {
                  $(elem).mask("999999999999A19", {
                    translation: {
                      "9": { pattern: /\d/, optional: true },
                      "1": { pattern: /\d/, optional: false },
                      A: { pattern: /[,.]/ }
                    }
                  });
                }

                // ppe number code mask
                if ($(elem).hasClass("js-mask-ppe")) {
                  $(elem).mask("PLENED0000059&&&&&&&&&&&&&&&&&&&", {
                    translation: {
                      "0": { pattern: /0/ },
                      "5": { pattern: /5/ },
                      "9": { pattern: /9/ },
                      "&": { pattern: /[0-9]/ }
                    }
                  });
                }

                if ($(elem).hasClass("js-mask-nip")) {
                  var options = {
                    onKeyPress: function(cep, e, field, options) {
                      var masks = [
                        "000-000-00-00",
                        "000-00-00-000",
                        "0000000000",
                        "0001001101100"
                      ];
                      var regex0 = /^[0-9]{3}\-[0-9]{3}/;
                      var regex1 = /^[0-9]{3}\-[0-9]{2}\-/;
                      var regex2 = /^[0-9]{4}/;
                      if (
                        regex0.test(cep) &&
                        !regex1.test(cep) &&
                        !regex2.test(cep)
                      ) {
                        $(elem).mask(masks[0], options);
                      } else if (
                        !regex0.test(cep) &&
                        regex1.test(cep) &&
                        !regex2.test(cep)
                      ) {
                        $(elem).mask(masks[1], options);
                      } else if (
                        !regex0.test(cep) &&
                        !regex1.test(cep) &&
                        regex2.test(cep)
                      ) {
                        $(elem).mask(masks[2], options);
                      } else {
                        $(elem).mask(masks[3], options);
                      }
                    },
                    translation: {
                      "1": { pattern: /[0-9\-]/ }
                    }
                  };

                  $(elem).mask("000-001101100", options);
                }

                /* END OF NEW MASKS */

                /* NEW CHARS DELETERS */

                // helper to restore cursor to proper place - when removing chars cursor is moved to the end - need to fix that
                $.fn.getCursorPosition = function() {
                  var input = this.get(0);
                  if (
                    !input ||
                    $(input).attr("type") === "email" ||
                    $(input).attr("type") === "number" ||
                    $(input).attr("type") === "date"
                  )
                    return; // No (input) element found
                  if ("selectionStart" in input) {
                    // Standard-compliant browsers
                    return input.selectionStart;
                  } else if (document.selection) {
                    // IE
                    input.focus();
                    var sel = document.selection.createRange();
                    var selLen = document.selection.createRange().text.length;
                    sel.moveStart("character", -input.value.length);
                    return sel.text.length - selLen;
                  }
                };

                // set cursor position
                function setSelectionRange(
                  input,
                  selectionStart,
                  selectionEnd
                ) {
                  if (
                    $(input).attr("type") === "email" ||
                    $(input).attr("type") === "number" ||
                    $(input).attr("type") === "date"
                  )
                    return;
                  if (input.setSelectionRange) {
                    input.focus();
                    input.setSelectionRange(selectionStart, selectionEnd);
                  } else if (input.createTextRange) {
                    var range = input.createTextRange();
                    range.collapse(true);
                    range.moveEnd("character", selectionEnd);
                    range.moveStart("character", selectionStart);
                    range.select();
                  }
                }

                function setCaretToPos(input, pos) {
                  setSelectionRange(input, pos, pos);
                }

                //old function - delete after complete switch to new validators
                $.fn.onlyDigits = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^0-9\.]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                // old function - delete after complete switch to new validators
                $.fn.onlyLetters = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[0-9\.\+\,\!\@\#\$\%\^\&\*\(\)\;\\\/\<\>\"\?\_\|\:\=\'\[\]\{\}]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                // old function - delete after complete switch to new validators
                $.fn.onlyLettersAndSpecial = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[0-9\.\+\!\@\#\$\%\^\&\*\(\)\;\\\<\>\"\?\_\|\:\=\'\[\]\{\}]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                // old function - delete after complete switch to new validators
                $.fn.noSpaces = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[ ]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                // alpha mask
                $.fn.maskAlpha = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^A-Za-z]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-alpha")) {
                  $(elem).maskAlpha();
                }

                // alpha_pl mask
                $.fn.maskAlphaPl = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^A-Za-ząĄęĘóśŚÓłŁżŻźŹćĆńŃ]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-alpha-pl")) {
                  $(elem).maskAlphaPl();
                }

                // alpha_pl_de mask
                $.fn.maskAlphaPlDe = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^A-Za-ząĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-alpha-pl-de")) {
                  $(elem).maskAlphaPlDe();
                }

                // email mask
                $.fn.maskEmail = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^-a-zA-Z0-9_+\.@]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-email")) {
                  $(elem).maskEmail();
                }

                // firstnames mask
                $.fn.maskFirstnames = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-ZąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\s\/|,']/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-firstnames")) {
                  $(elem).maskFirstnames();
                }

                // firstnames mask
                $.fn.maskFirstnamesLastname = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-ZąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\s\/|,\-']/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-firstnames-lastname")) {
                  $(elem).maskFirstnamesLastname();
                }

                // lastname mask
                $.fn.maskLastname = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-ZąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\s\-']/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-lastname")) {
                  $(elem).maskLastname();
                }

                // name mask
                if ($(elem).hasClass("js-mask-name")) {
                  $(elem).maskLastname();
                }

                //building number mask
                $.fn.maskBuildingNumber = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-Z0-9ąĄęĘóśŚÓłŁżŻźŹćĆńŃ\-\s\/\\]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-building-number")) {
                  $(elem).maskBuildingNumber();
                }

                // street mask
                $.fn.maskStreet = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-Z0-9ąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\.\-\s\'\"]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-street")) {
                  $(elem).maskStreet();
                }

                // address mask
                $.fn.maskAddress = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-Z0-9ąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\,\\\/\.\-\s\'\"]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-address")) {
                  $(elem).maskAddress();
                }

                // city mask
                $.fn.maskCity = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^a-zA-ZąĄęĘóśŚÓłŁżŻźŹćĆńŃÄäÖöÜüß\.\-\s]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-city")) {
                  $(elem).maskCity();
                }

                // url mask
                $.fn.maskUrl = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(
                      /[^-a-zA-Z0-9:%_\+.~#?&//=]/g,
                      ""
                    );
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-url")) {
                  $(elem).maskUrl();
                }

                // number mask
                $.fn.maskNumber = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^0-9.+-]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-number")) {
                  $(elem).maskNumber();
                }

                // number not negativ mask
                $.fn.maskNumberNotNegative = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^0-9.+]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-number-not-negative")) {
                  $(elem).maskNumberNotNegative();
                }

                // number integer mask
                $.fn.maskNumberInteger = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^0-9-+]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-number-integer")) {
                  $(elem).maskNumberInteger();
                }

                // digit mask
                $.fn.maskDigits = function() {
                  $(this).keyup(function() {
                    var pos = $(this).getCursorPosition();
                    var length = this.value.length;
                    this.value = this.value.replace(/[^0-9]/g, "");
                    if (pos > 0 && length > this.value.length) {
                      pos--;
                    }
                    setCaretToPos($(this)[0], pos);
                  });
                };

                if ($(elem).hasClass("js-mask-digits")) {
                  $(elem).maskDigits();
                }

                /* END OF NEW CHARS DELETERS */

                // postcode
                if ($(elem).attr("data-type") === "postcode") {
                  rulesObj.rules[name].pattern = /^[0-9\-\s]+$/;
                  rulesObj.rules[name].minlength = 5;
                  rulesObj.messages[name].minlength =
                    "Wprowadź 5 liczb kodu pocztowego.";
                  rulesObj.messages[name].pattern =
                    "Podany format kodu jest nieprawidłowy. Spróbuj ponownie.";
                }

                // strlen max
                if ($(elem).attr("data-strlen-max") !== undefined) {
                  var chars = parseInt($(elem).attr("data-strlen-max"));
                  rulesObj.rules[name].maxlength = chars;
                  if (chars === 1) {
                    rulesObj.messages[name].maxlength =
                      "Wprowadź maksymalnie {0} znak.";
                  } else if (
                    (chars > 15 || chars < 10) &&
                    (chars % 10 === 2 || chars % 10 === 3 || chars % 10 === 4)
                  ) {
                    rulesObj.messages[name].maxlength =
                      "Wprowadź maksymalnie {0} znaki.";
                  } else {
                    rulesObj.messages[name].maxlength =
                      "Wprowadź maksymalnie {0} znaków.";
                  }
                }

                // strlen mix
                if ($(elem).attr("data-strlen-min") !== undefined) {
                  var chars = parseInt($(elem).attr("data-strlen-min"));
                  rulesObj.rules[name].minlength = chars;
                  if (chars === 1) {
                    rulesObj.messages[name].minlength = "Wprowadź {0} znak.";
                  } else if (
                    (chars > 15 || chars < 10) &&
                    (chars % 10 === 2 || chars % 10 === 3 || chars % 10 === 4)
                  ) {
                    rulesObj.messages[name].minlength = "Wprowadź {0} znaki.";
                  } else {
                    rulesObj.messages[name].minlength = "Wprowadź {0} znaków.";
                  }
                }

                // digits only
                if ($(elem).attr("data-type") === "digit") {
                  rulesObj.rules[name].number = true;
                  rulesObj.messages[name].number = "Wprowadź wyłącznie cyfry.";
                }

                // digits only
                if ($(elem).attr("data-type") === "phone") {
                  rulesObj.rules[name].pattern = /([1-9]{1})([0-9]{8})/;
                  rulesObj.messages[name].pattern =
                    "Wprowadź poprawny numer telefonu.";
                  rulesObj.rules[name].number = true;
                  rulesObj.messages[name].number = "Wprowadź wyłącznie cyfry.";
                }

                // regex
                if ($(elem).attr("data-regex") !== undefined) {
                  rulesObj.rules[name].pattern = new RegExp(
                    $(elem).attr("data-regex")
                  );
                  rulesObj.messages[name].pattern = "Niepoprawne dane.";
                }

                if ($(elem).attr("data-require-from-group") !== undefined) {
                  var selector =
                    "[data-require-from-group = " +
                    $(elem).attr("data-require-from-group") +
                    "]";
                  var group = $(
                    "[data-require-from-group = " +
                      $(elem).attr("data-require-from-group") +
                      "]"
                  );
                  var amount = $(elem).attr("data-require-from-group-amount");
                  group.each(function() {
                    var name = $(this).attr("name");
                    if (rulesObj.rules[name] === undefined) {
                      rulesObj.rules[name] = {};
                    }
                    if (rulesObj.messages[name] === undefined) {
                      rulesObj.messages[name] = {};
                    }
                    rulesObj.rules[name].require_from_group = [
                      amount,
                      selector
                    ];
                    if ($("#fotowoltaika").length > 0) {
                      rulesObj.messages[name].require_from_group =
                        "Informacje niezbędne do kontaktu w celu realizacji zgłoszenia";
                    } else {
                      rulesObj.messages[name].require_from_group =
                        "Wypełnij przynajmniej {0} z tych pól.";
                    }
                  });
                }

                // value-range: wartość w przedziale (WIP)
                if (
                  $(elem).attr("data-value-min") !== undefined ||
                  $(elem).attr("data-value-max") !== undefined
                ) {
                  rulesObj.rules[name].range = [
                    $(elem).attr("data-value-min") !== undefined
                      ? parseInt($(elem).attr("data-value-min"))
                      : 0,
                    $(elem).attr("data-value-max") !== undefined
                      ? parseInt($(elem).attr("data-value-max"))
                      : 9999
                  ];
                  rulesObj.messages[name].range =
                    "Wprowadź wartość pomiędzy {0} a {1}.";
                  if ($(elem).attr("msg-range") !== undefined) {
                    rulesObj.messages[name].range = $(elem).attr("msg-range");
                  }
                }

                // EMAIL & CHECKBOX
                // email default messages
                if ($(elem).attr("type") === "email") {
                  //rulesObj.rules[name].required = true;
                  rulesObj.rules[name].email = true;
                  rulesObj.messages[name].required = "To pole jest wymagane.";
                }

                // checkbox default required message
                if ($(elem).attr("type") === "checkbox") {
                  //rulesObj.rules[name].required = true;
                  rulesObj.messages[name].required = "Zaznacz wybór.";
                  //if($(elem.attr('name') !== 'Zgoda1a')) {
                  //    rulesObj.rules[name].multiCheck = true;
                  //}
                }

                var attr = $(elem).attr("max-answers");
                if (typeof attr !== typeof undefined && attr !== false) {
                  rulesObj.rules[name].multiCheck = true;
                }

                //imie
                if (
                  $(elem)
                    .attr("name")
                    .match(/imie/i)
                ) {
                  $(this).onlyLettersAndSpecial();
                  //$(this).noSpaces();
                }

                //nazwisko
                if (
                  $(elem)
                    .attr("name")
                    .match(/nazwisko/i)
                ) {
                  $(this).onlyLetters();
                }

                //tel
                if (
                  $(elem)
                    .attr("name")
                    .match(/tel/i)
                ) {
                  $(this).onlyDigits();
                }

                if ($(elem).attr("data-type") === "digit") {
                  $(this).onlyDigits();
                }

                //pesel
                if (
                  $(elem)
                    .attr("name")
                    .match(/pesel/i)
                ) {
                  if (!$(elem).hasClass("js-validation-pesel8")) {
                    rulesObj.rules[name].pesel = true;
                  }
                  $(this).onlyDigits();
                }

                //regon
                if (
                  $(elem)
                    .attr("name")
                    .match(/regon/i)
                ) {
                  rulesObj.rules[name].regon = true;
                  $(this).onlyDigits();
                }
                // select default required message
                if ($(elem).is("select")) {
                  //rulesObj.rules[name].selectVisible = true;
                }
                // CUSTOM ERROR MESSAGES

                // custom required message
                if ($(elem).attr("msg-req") !== undefined) {
                  rulesObj.messages[name].required = $(elem).attr("msg-req");
                }
                if ($(elem).attr("msg-email") !== undefined) {
                  rulesObj.messages[name].email = $(elem).attr("msg-email");
                }
                if ($(elem).attr("msg-lastname") !== undefined) {
                  rulesObj.messages[name].lastname = $(elem).attr(
                    "msg-lastname"
                  );
                }
                if ($(elem).attr("msg-phone") !== undefined) {
                  rulesObj.messages[name].phone = $(elem).attr("msg-phone");
                }
                // custom error message
                if ($(elem).attr("msg-error") !== undefined) {
                  rulesObj.messages[name][name] = $(elem).attr("msg-error");
                  $.each(rulesObj.messages[name], function(key, message) {
                    if (key !== "required") {
                      rulesObj.messages[name][key] = $(elem).attr("msg-error");
                    }
                  });
                }
              }
            }); // end inputs loop

          // set error message container element
          rulesObj.errorElement = "div";
          // rulesObj.errorLabelContainer = 'label';

          // Selectric specific setup and hidden inputs with class check-it
          rulesObj.ignore =
            '.selectricInput, :hidden:not(select, input.check-it), [visible-select="false"]';
          rulesObj.errorPlacement = function(error, element) {
            // check if element has Selectric initialized on it
            var data = element.data("selectric");
            error.appendTo(
              data
                ? element.closest("." + data.classes.wrapper).parent()
                : element.parent()
            );
          };
          rulesObj.highlight = function(element, errorClass, validClass) {
            $(element)
              .addClass(errorClass)
              .removeClass(validClass); // default functionality
            if ($(element).is("select")) {
              $(element)
                .parents(".selectricWrapper")
                .find(".selectric")
                .addClass("selectric-error");
            } // for Selectric
          };
          rulesObj.unhighlight = function(element, errorClass, validClass) {
            $(element)
              .removeClass(errorClass)
              .addClass(validClass); // default functionality
            if ($(element).is("select")) {
              $(element)
                .parents(".selectricWrapper")
                .find(".selectric")
                .removeClass("selectric-error");
            } // for Selectric
          };

          // do something on successful submit
          rulesObj.submitHandler = function(form) {
            var sel = $(form).attr("id");
            var isValid = $(form).valid();

            // get additional info what to do when submitting the form
            if ($(form).attr("data-onsubmit") !== undefined) {
              eval($(form).attr("data-onsubmit"));
            }

            // print debug info on submit
            if (rulesObj.debug === true) {
              if ($(".success-msg", form).length === 0) {
                $(form).append(
                  '<span class="success-msg" style="display:none;">Debug info: Formularz wysłany!</span>'
                );
              }
              $(".success-msg", form)
                .fadeIn(200)
                .delay(5000)
                .fadeOut(200);
            }

            if ($(form).attr("ajax") == "true") {
              if (isValid === true) {
                $(".ajax--loader").show();

                $.ajax({
                  type: $(form).attr("method"),
                  url: $(form).attr("action"),
                  data: $(form).serialize(),
                  dataType: "json"
                }).done(function(msg) {
                  $(".ajax--loader").hide();
                  if (msg.info) {
                    if (msg.info == true && msg.path != "") {
                      window.location.replace(msg.path);
                      return false;
                    }

                    sweetAlert("Informacja", msg.info);
                  } else if (msg.error) {
                    sweetAlert("Błąd", msg.error, "error");
                  }
                });
              }
              return false;
            } else if ($(form).attr("ajaxthis") == "true") {
            } else if ($(form).attr("ukcontactform") == "true") {
              UKformHandler(form);
            } else if ($(form).attr("multistepform") == "true") {
              multiStepFormHandler(form);
            } else if ($(form).attr("id") == "accord-bank-number-checker") {
            } else {
              if (isValid === true) {
                if ($(form).find(".g-recaptcha").length > 0) {
                  var response =
                    $(".g-recaptcha").length === 1
                      ? grecaptcha.getResponse()
                      : grecaptcha.getResponse(
                          $(form)
                            .find(".g-recaptcha")
                            .attr("data-widget-id")
                        );
                }

                if ($(form).find(".g-recaptcha").length > 0 && response == "") {
                  if ($(form).find(".recaptcha-error").length == 0) {
                    $(form)
                      .find(".g-recaptcha")
                      .after('<div class="recaptcha-error error"></div>');
                  }
                  $(form)
                    .find(".recaptcha-error")
                    .html("Proszę uzupełnić captchę.")
                    .show();
                  return;
                } else if ($(form).find(".g-recaptcha").length > 0) {
                  $(form)
                    .find(".recaptcha-error")
                    .html("");
                }

                if (sel === "zostan-klientem-form") {
                  var pathName = document.location.pathname.replace(
                    "zamow-kontakt",
                    "formularz-wyslany"
                  );

                  ga("send", "pageview", pathName);
                }
                if (sel === "formularz_kontaktowy") {
                  var pathName = document.location.pathname.replace(
                    "napisz-do-nas",
                    "formularz-wyslany"
                  );

                  ga("send", "pageview", pathName);
                }

                form.submit();
              }
            }
          };

          // create validator object, passing created rulesObj
          formValidators[selector] = $(selector).validate(rulesObj);

          if ($("#Aktualizacja_danych_klienta").length > 0) {
            $('input[name="Telefon2"]').bind("keyup", function() {
              $('input[name="Telefon"]').valid();
            });

            $('input[name="Telefon"]').bind("keyup", function() {
              $('input[name="Telefon2"]').valid();
            });
          }

          $(document).on(
            "blur",
            'input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea',
            function() {
              if ($(this).parents("form").length) {
                var val = $(this).val();
                $(this).val(val.replace(/\s*$/, ""));
                $(this).valid();
              }
            }
          );

          $(document).on(
            "change",
            'select, input[type="checkbox"], input[type="radio"]',
            function() {
              if ($(this).parents("form").length) {
                $(this).valid();
              }
            }
          );

          // print debug info
          if (rulesObj.debug === true) {
          }
        }
      }
    },

    setPrintPage: function(selector) {
      var print = $(".print");
      if (print.length > 0) {
        print.click(function(e) {
          e.preventDefault();

          window.print();
          return false;
        });
      }

      return false;
    },
    setSwitchers: function(selector) {
      if (
        document.URL.indexOf("dlafirmy") >= 0 &&
        $(".radio-switch-forwhom").length > 0
      ) {
        $('.radio-switch-forwhom[value="firma"]')
          .click()
          .click();
      }

      if (
        document.URL.indexOf("dladomu") >= 0 &&
        $(".radio-switch-forwhom").length > 0
      ) {
        $('.radio-switch-forwhom[value="dom"]')
          .click()
          .click();
      }

      // Debug mode - to remove
      if (
        document.URL.indexOf("do_testow_divante") >= 0 &&
        $(".radio-switch-forwhom").length > 0
      ) {
        $('.radio-switch-forwhom[value="dom"]')
          .click()
          .click();
      }

      if (document.URL.indexOf("en/forbusiness") >= 0) {
        $(".main-tabs")
          .find(".active")
          .removeClass("active");
        $('li[data-url="en/forbusiness"]').addClass("active");
      }

      if (document.URL.indexOf("en/eneagroup") >= 0) {
        $(".main-tabs")
          .find(".active")
          .removeClass("active");
        $('li[data-url="en/eneagroup"]').addClass("active");
      }

      if (document.URL.indexOf("en/forhome") >= 0) {
        $(".main-tabs")
          .find(".active")
          .removeClass("active");
        $('li[data-url="en/forhome"]').addClass("active");
      }
    },
    setFontChanger: function(selector) {
      var changer = $(".font-size"),
        options = ["p", "a", "span"],
        selectors = options.join(", "),
        counter = 0,
        jump = 2,
        helpers = {
          update: function(size) {
            $(selectors).each(function() {
              $(this).css(
                "font-size",
                parseInt($(this).css("font-size")) + size
              );
            });

            return false;
          }
        };

      if (changer.length > 0) {
        changer.click(function(e) {
          e.preventDefault();
          counter++;

          // maximum font size state
          if (counter == 1) {
            helpers.update(jump);
          }

          // minimum font size state
          if (counter == 2) {
            helpers.update(-(jump * 2));
          }

          // back to standard font size
          if (counter == 3) {
            helpers.update(jump);
            counter = 0;
          }

          return false;
        });
      }
    },
    setFiltersTriangle: function(selector) {
      var $filter_tabs = $(".filters")
        .parent()
        .find(".custom-tabs-nav");

      if ($filter_tabs.length > 0) {
        $filter_tabs.find("> li").each(function(key, item) {
          $(this)
            .find("> a")
            .append("<span class='triangle-down' />");
          $(this)
            .find("span.triangle-down")
            .css({
              "border-right": $(this).width() / 2 + "px solid transparent",
              "border-left": $(this).width() / 2 + "px solid transparent"
            });
        });
      }
    },
    setDatepicker: function(selector) {
      var datePicker = $(selector);
      if (
        $(selector).hasClass("js-validation-old-date-not-future") ||
        $(selector).hasClass("js-validation-date-not-future")
      ) {
      }

      if (datePicker.length > 0) {
        $.datepicker.regional["pl"] = {
          closeText: "Zamknij",
          prevText: "Poprzedni",
          nextText: "Następny",
          currentText: "Dzisiaj",
          monthNames: [
            "Styczeń",
            "Luty",
            "Marzec",
            "Kwiecień",
            "Maj",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień"
          ],
          monthNamesShort: [
            "Sty",
            "Lu",
            "Mar",
            "Kw",
            "Maj",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Pa",
            "Lis",
            "Gru"
          ],
          dayNames: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota"
          ],
          dayNamesShort: ["Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So"],
          dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
          weekHeader: "Tydz",
          dateFormat: "yy-mm-dd",
          firstDay: 1,
          isRTL: false,
          showMonthAfterYear: false,
          yearSuffix: ""
        };
        $.datepicker.setDefaults($.datepicker.regional["pl"]);
        datePicker.each(function() {
          if (
            $(this).hasClass("js-validation-old-date-not-future") ||
            $(this).hasClass("js-validation-date-not-future")
          ) {
            $(this).datepicker({
              maxDate: new Date(),
              onClose: function() {
                $(this).valid();
              }
            });
          } else {
            $(this).datepicker({
              onClose: function() {
                $(this).valid();
              }
            });
          }
        });
      }
    },
    setBreakdownMap: function(selector) {
      var map = $("#breakdownMap");
      if (map.length > 0) {
        var breakdowns = [
          {
            name: "Bydgoszcz",
            coords: [468, 167],
            status: "breakdowns",
            count: "69",
            offsets: [0, 2]
          }
        ];

        map.vectorMap({
          map: "main-sector",
          zoomButtons: false,
          zoomOnScroll: false,
          backgroundColor: "#fff",
          regionStyle: {
            initial: {
              fill: "#00a8ff"
            },
            hover: {
              fill: "#007dd4",
              cursor: "pointer"
            }
          },
          markerStyle: {
            initial: {
              fill: "#b3b2b2",
              stroke: false
            },
            hover: {
              stroke: false
            }
          },
          markers: [
            //breakdowns.map(function(h){ return {name: h.name, latLng: h.coords} }),
            {
              coords: [137, 123],
              name: "Szczecin",
              style: { fill: "#65c3f3" }
            },
            {
              coords: [170, 238],
              name: "Gorzów Wlkp.",
              style: { fill: "#65c3f3" }
            },
            {
              coords: [188, 370],
              name: "Zielona Góra",
              style: { fill: "#65c3f3" }
            },
            {
              coords: [325, 289],
              name: "Poznań",
              style: { fill: "#65c3f3" }
            },
            {
              coords: [468, 197],
              name: "Bydgoszcz",
              style: { fill: "#65c3f3" }
            }
          ],
          series: {
            markers: [
              {
                attribute: "image",
                scale: {
                  breakdowns: "../img/basic/ico-mapppoint-bg.png"
                },
                values: breakdowns.reduce(function(p, c, i) {
                  p[i] = c.status;
                  return p;
                }, {})
              }
            ]
          },
          labels: {
            markers: {
              render: function(index) {
                return breakdowns[index].count;
              },
              offsets: function(index) {
                var offset = breakdowns[index]["offsets"] || [0, 0];

                return [offset[0] - 7, offset[1] + 3];
              }
            }
          },
          onRegionLabelShow: function(event, label, index) {
            label.html("");
            label.css({
              display: "none",
              color: "#66696d",
              "z-index": "-99999"
            });
          },
          onMarkerLabelShow: function(event, label, index) {
            label.html(
              '<img src="img/' +
                markers[index].image +
                '"><br />' +
                label.html()
            );
          },
          onRegionClick: function(event, name, region) {
            switch (name) {
              case "id0":
                regionHandlers.bind("#bydgoszcz-map-content");
                break;
              case "id1":
                regionHandlers.bind("#gorzow-map-content");
                break;
              case "id2":
                regionHandlers.bind("#zielonag-map-content");
                break;
              case "id3":
                regionHandlers.bind("#szczecin-map-content");
                break;
              case "id4":
                regionHandlers.bind("#poznan-map-content");
                break;
            }
          }
        });
        var mapData = map.vectorMap("get", "mapObject");
        var markers = mapData.markers,
          marker;
        for (marker in markers) {
          var element = markers[marker].element.node;
          var text = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
          );
          text.setAttribute("x", markers[marker].config.coords[0] - 20);
          text.setAttribute("y", markers[marker].config.coords[1] + 24);
          // custom set position
          if (marker == 2) {
            text.setAttribute("x", markers[marker].config.coords[0] - 38);
            text.setAttribute("y", markers[marker].config.coords[1] + 24);
          }
          if (marker == 4) {
            text.setAttribute("x", markers[marker].config.coords[0] - 65);
            text.setAttribute("y", markers[marker].config.coords[1] + 24);
          }
          var uppercase = markers[marker].config.name;
          text.textContent = uppercase.toUpperCase();
          text.setAttribute("font-size", "11");
          text.setAttribute("font-family", "Signika");
          text.setAttribute("style", "fill: #ffffff");
          text.setAttribute("font-weight", "500");

          if (element.parentNode) {
            element.parentNode.appendChild(text);
          }
        }

        var regionHandlers = {
          bind: function(id) {
            $(id).show();
            return false;
          }
        };

        // closing window on close button click
        $(".close-map-layer").on("click", function() {
          var close = $(this);
          close = close.parent();
          close.parent().hide();
        });

        // closing window on click outside map point
        $(".map-image").on("click", function() {
          var close = $(this);
          close = close.parent();
          close.parent().hide();
        });

        $("#map-szczecin").on("click", function() {
          regionHandlers.bind("#szczecin-map-content");
        });

        $("#map-poznan").on("click", function() {
          regionHandlers.bind("#poznan-map-content");
        });

        $("#map-bydgoszcz").on("click", function() {
          regionHandlers.bind("#bydgoszcz-map-content");
        });

        $("#map-gorzow").on("click", function() {
          regionHandlers.bind("#gorzow-map-content");
        });

        $("#map-zielonag").on("click", function() {
          regionHandlers.bind("#zielonag-map-content");
        });
      }
    },
    setMassiveFailuresMap: function(selector) {
      var map = $("#massive-failures-map");

      $(".massive-failures .advanced-switch").click(function() {
        $(".massive-failures .advanced-switch").toggleClass(
          "advanced-switch--active"
        );
        $(".massive-failures .fields-wrapper").toggleClass(
          "fields-wrapper--active"
        );
      });

      if (map.length > 0) {
        map.vectorMap({
          map: "massive-failures-map",
          backgroundColor: "transparent",
          zoomOnScroll: false,
          zoomButtons: false,
          hoverColor: false,
          hoverOpacity: 1,
          regionStyle: {
            initial: {
              "fill-opacity": 1,
              fill: "#2099cd"
            },
            hover: {
              "fill-opacity": 1,
              fill: "#2e2c70"
            }
          },
          series: {
            regions: [
              {
                values: {
                  gorzow: "Gorzów",
                  bydgoszcz: "Bydgoszcz",
                  szczecin: "Szczecin",
                  zielonagora: "Zielona Góra",
                  poznan: "Poznań"
                },
                attribute: {
                  fill: "#2099cd",
                  "fill-opacity": 1,
                  stroke: "#FFFFFF"
                }
              }
            ]
          },
          onRegionLabelShow: function(e, el, code) {
            e.preventDefault();
          }
        });
      }
    },

    setHoverNavigation: function(selector) {
      var navigation = $(".header-bottom nav > ul.hover-menu > li").not(".no-hover");
      if (navigation.length > 0) {
        navigation.hoverIntent(
          function() {
            $(this).addClass("hover");
            if (!$(this).hasClass("single-item")) {
              $(".menu-overlay").css({ display: "block" });
            }
          },
          function() {
            $(this).removeClass("hover");
            $(".menu-overlay").css({ display: "none" });
          }
        );
      }
    },
    setMainNavigationFix: function(selector) {
      var mainMenu = $(".header-bottom nav > ul.hover-menu");
      if (mainMenu.children().length > 3) {
        mainMenu.addClass("small-font");
      }
      $("ul.hover-menu").css("visibility", "visible");
      var windowSize = $(this);
      $(".nav.short:empty")
        .closest(".submenu-right")
        .addClass("short-empty");
      $(window).on("resize", function() {
        windowSize = $(this);
        $(".header-bottom nav ul li").hover(function() {
          var width = $("div:first", this).width(),
            offset = $(this).position().left;
          if (width + offset > windowSize.width()) {
            $(this).toggleClass("fixed-position");
            $(this)
              .parent()
              .toggleClass("pos-relative");

            if ($(this).find(".nav.short").length == 0) {
              if ($(this).hasClass("fixed-position")) {
                $(this)
                  .find(".submenu-right")
                  .css("right", "-426px");
              }
            }
          }
        });
      });
    },
    setBipDocuments: function(selector) {
      var container = $(".content-docs-register .versions-elements");

      if (container.length > 0) {
        var x = $("tr", container);

        if (x.length >= 5) {
          $(x)
            .slice(5, x.length)
            .css("display", "none");
          var button = $(".show-more-docs-register");
          button.css("display", "block");
          button.click(function(e) {
            e.preventDefault();
            $(".versions-elements-head").show();
            $(x).show();
            $(this).hide();
          });
        }
      }
    },
    setCalcController: function(selector) {
      $(document).on("click", ".table-arrow", function(e) {
        e.preventDefault();

        $(this).toggleClass("close");
        $(this)
          .parents()
          .eq(2)
          .find(".collapse")
          .toggle();
      });

      $(document).on("click", ".calc .field label", function() {
        if ($(this).hasClass("forCheckbox")) {
          $(this)
            .prev()
            .click();
          return false;
        }
        $(this)
          .parent()
          .parent()
          .find(".active")
          .removeClass("active");
        $(this)
          .prev()
          .addClass("active");

        if (
          $(this)
            .parent()
            .parent()
            .find(".person-more-field").length > 0
        ) {
          if (
            $(this)
              .prev()
              .prev()
              .hasClass("person-more")
          ) {
            $(".person-more-field").show();
          } else {
            $(".person-more-field").hide();
          }
        }
      });

      $(".calc .field label")
        .mouseenter(function() {
          $(this)
            .prev()
            .addClass("hover");
        })
        .mouseleave(function() {
          $(this)
            .parent()
            .parent()
            .find(".hover")
            .removeClass("hover");
        });

      $(document).on("click", ".calc .field .fake-click", function() {
        if (
          $(this)
            .parent()
            .parent()
            .find(".person-more-field").length > 0
        ) {
          if (
            $(this)
              .prev()
              .hasClass("person-more")
          ) {
            $(".person-more-field").show();
          } else {
            $(".person-more-field").hide();
          }
        }

        $(this)
          .parent()
          .parent()
          .find(".active")
          .removeClass("active");
        $(this).addClass("active");
        $(this)
          .prev()
          .click();
      });

      $(document).on(
        "click",
        ".calc .field .bolt-click,  .calc .field .need-click",
        function() {
          $(this)
            .prev()
            .click();

          if (
            $(this)
              .prev()
              .is(":checked")
          ) {
            $(this).addClass("active");

            if (
              $(this)
                .parent()
                .parent()
                .find(".needs-more-field").length > 0
            ) {
              if ($(this).is(".fake-needs-2")) {
                $(".needs-more-field").show();
              }
            }
          } else {
            $(this).removeClass("active");

            if (
              $(this)
                .parent()
                .parent()
                .find(".needs-more-field").length > 0
            ) {
              if ($(this).is(".fake-needs-2")) {
                $(".needs-more-field").hide();
              }
            }
          }
        }
      );
    },
    setReadMore: function(selector) {
      var wysiwyg = $(".wysiwyg-container"),
        rmbutton = $(".read-more-button");

      function readMore(element) {
        var nextAll = $(element)
          .find('div[style*="page-break-after: always"]')
          .nextUntil(".wysiwyg-container");
        var $div = $("<div />").addClass("read-more-container");

        $(element)
          .find('div[style*="page-break-after: always"]')
          .after($div);

        nextAll.each(function() {
          var read = $(this).detach();
          $(element)
            .find(".read-more-container")
            .append(read);
        });

        $(element)
          .find(".read-more-container")
          .after('<a href="#" class="read-more-button link">Czytaj więcej</a>');
      }

      wysiwyg.each(function() {
        readMore(this);
      });

      $(".read-more-button").on("click", function(event) {
        event.preventDefault();
        $(this)
          .prev(".read-more-container")
          .slideDown();
        $(this).fadeOut();
      });
    },
    setChainedSelects: function(selector) {
      //var region = $('#departmentId'),
      //    city = $('#regionId');
      //
      //if ((region.length > 0) && (city.length > 0)) {
      //
      //   // city.chained(region);
      //
      //    region.selectric();
      //    city.selectric();
      //
      //    region.selectric({
      //        onClose: function () {
      //            city.selectric('refresh');
      //        }
      //    });
      //
      //}
    },
    setMobileMenu: function(selector) {
      var openButton = $('a[href="#mobile-menu"]');

      if (openButton.length > 0) {
        $("#mobile-menu")
          .mmenu(
            {
              offCanvas: {
                position: "right"
              }
            },
            {
              offCanvas: {
                pageNodetype: "#wrapper"
              }
            }
          )
          .on("opening.mm", function() {
            openButton.addClass("active");
          })
          .on("closing.mm", function() {
            openButton.removeClass("active");
          });
      }
    },
    setResponsiveTabs: function(selector) {
      if (selector) {
        $(selector).responsiveTabs({
          rotate: false,
          collapsible: "accordion",
          setHash: false
        });
      }
    },
    setInputFilter: function(selector) {
      if (selector) {
        $(selector).keyup(function() {
          this.value = this.value.replace(/[^0-9\.]/g, "");
        });
      }
    },
    setFormPagination: function(selector) {
      var button = $("button[name^='str']");
      if (button.length > 0) {
        $(document).on("click", button, function() {
          var btn = $(this).attr("name");
          if (btn) {
            var parts = btn.split("_");
            var $parent = $(this).parent();

            if (parts.length > 0) {
              if (parts[1].length > 0) {
                $('fieldset[name^="str"]').hide();
                if (parts[1] === "dalej") {
                  $parent.next('fieldset[name^="str"]').show();
                }

                if (parts[1] === "wstecz") {
                  $parent.prev('fieldset[name^="str"]').show();
                }
              }
            }
          }
        });
      }
    },
    setOnResize: function() {
      var menuCopy = "",
        ebokBtn = "",
        isCopied = false,
        isDevSmall = false;

      //$('.open-search').bind('click', function () {
      //    $(this).toggleClass('active');
      //    var search = $('#searchForm');
      //    search.toggle();
      //});

      $(window).on("resize", function() {
        var windowWidth = $(window).width();

        if (windowWidth < 1200) {
          var secondActive = $(
            "aside.left > .widget-multilevel-navigation > ul > li.active"
          );
          //secondActive.prependTo('aside.left > .widget-multilevel-navigation > ul');
          $(
            "aside.left > .widget-multilevel-navigation > ul > li.active > a"
          ).attr("href", "#");

          $(".widget-multilevel-navigation > ul > li.active > a").click(
            function(e) {
              if (opened == true) {
                e.preventDefault();
                $(this)
                  .parents(".widget-multilevel-navigation > ul")
                  .removeClass("opened");
                opened = false;
              } else {
                e.preventDefault();
                $(this)
                  .parents(".widget-multilevel-navigation > ul")
                  .addClass("opened");
                opened = true;
              }
            }
          );
          if (isCopied === false) {
            //menuCopy = $(".header-top > .row > ul.main-tabs").clone();
            //ebokBtn = $(".header-top > .row > a.btn.small").clone();
            isCopied = true;
          }
          isDevSmall = true;

          if (!!$.prototype.accordion) {
            $("footer .col-sm-3 .widget-navigation").accordion({
              activate: 2,
              collapsible: true,
              active: false
            });
          }
          var activeElement = $(
            ".header-top > .row > ul.main-tabs > li.active"
          );
          activeElement.prependTo(".header-top > .row > ul.main-tabs");
          $(".header-top > .row > ul.main-tabs > li.active > a").attr(
            "href",
            "#"
          );

          var opened = false;
          activeElement.click(function() {
            if (opened == true) {
              $(this)
                .parent("ul.main-tabs")
                .removeClass("opened");
              opened = false;
            } else {
              $(this)
                .parent("ul.main-tabs")
                .addClass("opened");
              $("#searchForm").removeClass("expanded");
              $(".open-search").removeClass("active");
              opened = true;
            }
          });
        } else {
          if (isCopied === true && isDevSmall === true) {
            if ($(".header-top > .row .main-tabs").length > 0) {
              $(".header-top > .row .main-tabs").replace(menuCopy);
              $(".header-top > .row").append(ebokBtn);
              isDevSmall = false;
            }
          }
        }
      });
      $(document).ready(function() {
        $(window).trigger("resize");
      });
    },
    newsletterTermsValidation: function() {
      // @param s {string} - name of selector
      // @param eq {int} - content eq
      // @param c {int} - number of char will script cut the text
      $.fn.wrapText = function(s, eq, c) {
        var self = $(this),
          x,
          terms;

        var wrapLabel = $(".newsletters-wrap-label").text(),
          unwrapLabel = $(".newsletters-unwrap-label").text();

        $(this)
          .contents()
          .eq(eq)
          .wrap('<span class="mini js-terms"/>');

        x = $(".js-terms", self).clone();
        x.removeClass("mini js-terms")
          .addClass("full js-terms-full")
          .hide();

        $(this).append(x);

        terms = $(".js-terms", self)
          .text()
          .trim();
        $(".js-terms", self).html(
          terms.substr(0, c) + '... <a href="#">' + unwrapLabel + "</a>"
        );
        $(this).append(
          '<div id="' +
            s +
            '-error" class="error" style="display: none;"></div>'
        );
        $(".js-terms-full", self).append('<a href="#">' + wrapLabel + "</a>");

        $(".js-terms", self).click(function(e) {
          e.preventDefault();
          $(this).hide();
          $(".js-terms-full", self).show();
          return false;
        });

        $(".js-terms-full", self).click(function(e) {
          e.preventDefault();
          $(this).hide();
          $(".js-terms", self).show();
          return false;
        });

        $(document).on("click", self, function(e) {
          if ($(e.target).is(self)) {
            return false;
          }

          if ($(e.target).is("#" + s + "-error")) {
            return false;
          }
        });
      };

      var x = $("#newsletters");
      if (x.length > 0) {
        $("label[for=accept-terms]", x).wrapText("accept-terms", 2, 81);
        $("label.term-optional", x).wrapText("term-optional", 0, 76);
      }

      var y = $("#jobnewsletters");
      if (y.length > 0) {
        $("label[for=accept-terms]", y).wrapText("accept-terms", 2, 81);
        $("label.term-optional", y).wrapText("term-optional", 0, 67);
      }

      /*function resetForm(selector) {
             $('div.error', selector).css('display', 'none');
             $('input', selector).removeClass('error');
             $('*', selector).removeClass('selectric-error');
             $('textarea', selector).removeClass('error');
             location.href = "#fundacjaformularzaplikacyjny";
             selector.css("display", "block");
             }

             function validMultiForm() {

             $('form').has('fieldset').each(function () {
             $(this).find('fieldset').css('display', 'block');

             $(this).find('textarea').valid();
             $(this).find('select').valid();
             $(this).find('input').valid();

             $(this).find('fieldset').css('display', 'none');
             });

             $('form > fieldset').css('display', 'none');

             var find = false;
             $('form > fieldset').has('select.error, textarea.error, input.error').each(function () {
             if (find == false) {
             find = true;
             $(this).css('display', 'block');
             }
             });
             }

             $(document).on("click", '.js-multiForm', function () {
             validMultiForm();
             });

             $(document).on("click", 'button[name=str1_dalej]', function () {
             resetForm("fieldset[name=str2]");
             });

             $(document).on("click", 'button[name=str2_dalej]', function () {
             resetForm("fieldset[name=str3]");
             var isValid = $("#fundacjaformularzaplikacyjny");
             if (isValid.valid() === true) {
             $('fieldset[name=str3]', isValid).css("display", "block");
             }
             });*/
    },
    setMultiformActions: function() {
      function resetForm(selector) {
        $("div.error", selector).css("display", "none");
        $("input", selector).removeClass("error");
        $("*", selector).removeClass("selectric-error");
        $("textarea", selector).removeClass("error");
        location.href = "#fundacjaformularzaplikacyjny";
        selector.css("display", "block");
      }

      function validMultiForm(step) {
        var step = $(step);
        step.find("textarea").valid();
        step.find("select").valid();
        step.find("input").valid();
        step.hide();
        step.next("fieldset").show();
        var find = false;
        step.has("select.error, textarea.error, input.error").each(function() {
          if (find == false) {
            find = true;
            step.show();
            step.next("fieldset").hide();
          }
        });
      }

      $(document).on("click", ".js-multiForm", function() {
        var step = $(this).closest("fieldset");
        validMultiForm(step);

        $("html, body").scrollTop(
          $(this)
            .parents("form:first")
            .offset().top
        );
      });
      $("form .btn-left").click(function() {
        var parent = $(this).closest("fieldset");
        if (parent.length) {
          parent
            .hide()
            .prev("fieldset")
            .show();
        }

        $("html, body").scrollTop(
          $(this)
            .parents("form:first")
            .offset().top
        );
      });
    },
    dynamicForm: function(form, max) {
      this.element = 0;
      this.max = max || 5;
      this.form = $(form);
      this.initialized = false;

      this.initialize = function() {
        var attr;

        $.each(this.form.find("fieldset[name='DanePpe'] input"), function(
          value,
          index
        ) {
          attr = $(this).attr("type");

          if (attr != "submit") {
            $(this).attr("name", "DanePpe[" + $(this).attr("name") + "][]");
          }
        });

        if (this.form.find("fieldset[name='DanePpe']").length > 0) {
          this.form
            .find("fieldset[name='DanePpe']")
            .after(
              '<div class="dynamic-btn-group clearfix"><button type="button" class="btn dynamic-btn js-add-element">dodaj nowy punkt</button> <button type="button" class="btn dynamic-btn js-remove-element">usuń</button></div>'
            );
          this.initialized = true;
        }
      };

      this.addElements = function() {
        var clone;
        var $this = this;

        if (this.initialized == false) {
          return false;
        }

        if (this.element >= this.max) {
          this.element = this.max;
          return false;
        }

        this.element++;

        $(".js-remove-element").show();

        if (typeof $("select").selectric() === "object") {
          $("select").selectric("destroy");

          //jQuery('select').selectric('init');
        }

        clone = this.form.find("fieldset[class!=js-dynamic]").clone(true);
        clone.addClass("js-dynamic");

        $.each(clone.find("select"), function(value, index) {
          $(this)
            .prev("label")
            .attr("id", $(this).attr("id") + "_sel_" + $this.element);
          $(this)
            .prev("label")
            .find(".asterix")
            .remove();
          $(this).attr("id", $(this).attr("id") + "_sel_" + $this.element);
          //$this.jQuery(this).val('');
        });

        $.each(clone.find("input"), function(value, index) {
          if ($(this).attr("name") == "DanePpe[DanePpeAdresNrMieszkania][]") {
            $(this)
              .prev("label")
              .find(".asterix")
              .remove();
          }
          $(this)
            .prev("label")
            .attr("id", $(this).attr("id") + "_" + $this.element);
          $(this).attr("id", $(this).attr("id") + "_" + $this.element);
          $(this).attr("name", $(this).attr("name") + "_" + $this.element);
          $(this).val("");
          if ($(this).hasClass("zip-code-mask")) {
            $(this).switchClass(
              "zip-code-mask",
              "zip-code-mask" + $this.element
            );
            $(document).on(
              "blur",
              ".zip-code-mask" + $this.element,
              function() {
                $(".zip-code-mask" + $this.element).mask("00-000", {
                  selectOnFocus: true
                });
              }
            );
          }
        });

        if (this.element >= this.max) {
          this.form.find(".js-add-element").addClass("disabled");
          var $div = $("<div />").addClass("field js-more-fields");
          var $check = $("<input />")
            .attr("type", "checkbox")
            .attr("name", "morePPE")
            .attr("id", "morePPE");
          var $label = $("<label />")
            .attr("for", "morePPE")
            .text(
              "Chcę skorzystać z oferty dla większej ilości miejsc dostarczania energii (PPE), niż " +
                (this.max + 1) +
                " podanych powyżej."
            );
          $check.appendTo($div);
          $label.appendTo($div);

          this.form
            .find(".js-add-element")
            .parent()
            .prepend($div);
          $("#morePPE").uniform();
        }

        this.form.find("fieldset:last").after(clone);

        if (typeof $("select").selectric() === "object") {
          $("select").selectric("init");
        }
      };

      this.removeElement = function() {
        this.form.find(".js-add-element").removeClass("disabled");

        if (this.initialized == false) {
          return false;
        }

        if (this.element <= 0) {
          this.element = 0;
          return false;
        }

        $(".js-more-fields").remove();

        this.element--;

        if (this.element <= 0) {
          $(".js-remove-element").hide();
        }

        this.form.find("fieldset[name='DanePpe']:last").remove();
      };
    },
    setDynamicForm: function(selector) {
      //execute
      var max = 4;
      var dynamicForm = this.dynamicForm;

      var form = new dynamicForm(".content form", max);
      form.initialize();

      $(document).on("click", ".js-add-element", function() {
        form.addElements();
      });

      $(document).on("click", ".js-remove-element", function() {
        form.removeElement();
      });
    },
    localStorageZamowienia: function() {
      if (!ENEA.localStorageSupport) {
        return;
      }
      if (localStorage.getItem("ZamowUmoweOnline") !== null) {
        var data = "";
        data = localStorage.getItem("ZamowUmoweOnline");
        data = JSON.parse(data);

        var formText = $("#ZamowUmoweOnline .text");
        formText.each(function() {
          if ($('input[name="' + $(this).prop("name") + '"]').length > 0) {
            $('input[name="' + $(this).prop("name") + '"]').val(
              data[$(this).prop("name")]
            );
          }
        });

        var formCheckbox = $(
          ".order-online #ZamowUmoweOnline input[type='checkbox']"
        );
        formCheckbox.each(function() {
          if (
            $('input[name="' + $(this).prop("name") + '"]').length > 0 &&
            data[
              $(this)
                .prop("name")
                .toLowerCase()
            ] == 1
          ) {
            $('input[name="' + $(this).prop("name") + '"]').prop(
              "checked",
              true
            );
            $(this)
              .click()
              .click();
          }
        });

        $(".grupa-taryfowa")
          .val(data.GrupaTaryfowa)
          .selectric("refresh");
      }
    },
    helperFunction: function() {
      var max = 0;

      $(".contact-numbers .widget-helpline .section").each(function() {
        var elheight = $(this).outerHeight();
        if (elheight > max) {
          max = elheight;
        }
      });

      $(".contact-numbers .widget-helpline .section").css("height", max + "px");

      max = 0;

      $(".special > .custom-tabs-nav > li").each(function() {
        if ($(this).innerHeight() > max) {
          max = $(this).innerHeight();
        }
      });

      $(".special > .custom-tabs-nav > li")
        .css("min-height", max + 10 + "px")
        .css("height", max + 10 + "px")
        .css("max-height", max + 10 + "px");
    },
    customOfferTabs: function() {
      $(".orderAgreement").hide();
      $(".orderAgreementImageTop").hide();
      $(".orderAgreementImage").hide();

      var orderAgreementBtns = $(".orderAgreement");
      var orderAgreementImgsTop = $(".orderAgreementImageTop");
      var orderAgreementImgs = $(".orderAgreementImage");

      $(".custom-tabs").each(function() {
        var $ul = $(this).find(".custom-tabs-nav");
        var $li = $ul.children("li");
        $li.each(function(index) {
          var $tabHash = $(this)
            .children("a")
            .attr("href");
          var $trescTaba = $($tabHash);

          if (orderAgreementBtns.length > 0) {
            if (typeof orderAgreementBtns[index] !== "undefined") {
              $(orderAgreementBtns[index]).attr(
                "data-href",
                $(this).attr("data-href")
              );
              if (index == 0) {
                $(orderAgreementBtns[index]).show();
              }
            }
          }

          if (orderAgreementImgsTop.length > 0) {
            if (typeof orderAgreementImgsTop[index] !== "undefined") {
              $(orderAgreementImgsTop[index]).attr(
                "data-href",
                $(this).attr("data-href")
              );
              if (index == 0) {
                $(orderAgreementImgsTop[index]).show();
              }
            }
          }

          if (orderAgreementImgs.length > 0) {
            if (typeof orderAgreementImgs[index] !== "undefined") {
              $(orderAgreementImgs[index]).attr(
                "data-href",
                $(this).attr("data-href")
              );
              if (index == 0) {
                $(orderAgreementImgs[index]).show();
              }
            }
          }

          if ($(".widget-filters").length <= 0) {
            if ($(this).is(":first-child")) {
              $(this).addClass("active");
              $trescTaba.show();
            } else {
              $trescTaba.hide();
            }
          }
          //}
        });

        $li.click(function() {
          $(this)
            .children("a")
            .click();
        });

        $li.children("a").click(function() {
          $li.removeClass("active");
          $li.each(function() {
            $(
              $(this)
                .children("a")
                .attr("href")
            ).hide();
          });
          $(this)
            .parent()
            .addClass("active");
          $($(this).attr("href")).show();

          if (
            $('.orderAgreement[data-href="' + $(this).attr("href") + '"]')
              .length > 0
          ) {
            $(".orderAgreement").hide();
            $(
              '.orderAgreement[data-href="' + $(this).attr("href") + '"]'
            ).show();
          }

          if (
            $(
              '.orderAgreementImageTop[data-href="' +
                $(this).attr("href") +
                '"]'
            ).length > 0
          ) {
            $(".orderAgreementImageTop").hide();
            $(
              '.orderAgreementImageTop[data-href="' +
                $(this).attr("href") +
                '"]'
            ).show();
          }

          if (
            $('.orderAgreementImage[data-href="' + $(this).attr("href") + '"]')
              .length > 0
          ) {
            $(".orderAgreementImage").hide();
            $(
              '.orderAgreementImage[data-href="' + $(this).attr("href") + '"]'
            ).show();
          }

          //window.location.hash = jQuery(this).attr('href').replace('-', '');
          return false;
        });
      });
    },
    zoomImg: function(selector) {
      var link = "/static/img/images/kso_faktura-wizualizacja_big.jpg";
      var selector = $(selector);
      if (selector) {
        selector.zoom({ url: link });
      }
    },
    setFilterActions: function(selector) {
      var selector = $(selector);
      if (selector.length) {
        if (selector.find("#procedure").length > 0) {
          var publicArr = selector
            .find("#procedure")
            .attr("data-type-public")
            .split(",");
          var privateArr = selector
            .find("#procedure")
            .attr("data-type-private")
            .split(",");
          var allArr = publicArr.concat(privateArr);

          selector.find(".left .radio").click(function() {
            var id = $(".checked input", this).attr("id");
            var select = $("#procedure");
            if (id == "radioPublic") {
              select.empty();
              select.append("<option></option>");
              for (var i = 0; i < publicArr.length; i++) {
                select.append("<option>" + publicArr[i] + "</option>");
                select.selectric("refresh");
              }
            } else if (id == "radioPrivate") {
              select.empty();
              select.append("<option></option>");
              for (var i = 0; i < privateArr.length; i++) {
                select.append("<option>" + privateArr[i] + "</option>");
                select.selectric("refresh");
              }
            } else {
              select.empty();
              select.append("<option></option>");
              for (var i = 0; i < allArr.length; i++) {
                select.append("<option>" + allArr[i] + "</option>");
                select.selectric("refresh");
              }
            }
          });
          $("#reset-data").click(function() {
            selector
              .find("#procedure")
              .empty()
              .append("<option disabled selected></option>")
              .selectric("refresh");
            selector.find("#radioHidden").prop("checked", true);
            selector.find('input[type="text"]').val("");
            $.uniform.update();
            selector
              .find("select")
              .prop("selectedIndex", 0)
              .selectric("refresh");
            $(selector)
              .find(".submit")
              .click();
          });
        }
      }
    },
    setCustomFormsActions: function(selector) {
      $.validator.addMethod(
        "age",
        function(value, element) {
          //If false, the validation fails and the message below is displayed
          var pesel = value;

          function getDate(pesel) {
            var year = parseInt(pesel.substring(0, 2), 10);
            var month = parseInt(pesel.substring(2, 4), 10);
            var day = parseInt(pesel.substring(4, 6), 10);

            if (month > 80) {
              year = year + 1800;
              month = month - 80;
            } else if (month > 60) {
              year = year + 2200;
              month = month - 60;
            } else if (month > 40) {
              year = year + 2100;
              month = month - 40;
            } else if (month > 20) {
              year = year + 2000;
              month = month - 20;
            } else {
              year += 1900;
            }
            if (month.toString().length == 1) {
              month = "0" + month;
            }
            if (day.toString().length == 1) {
              day = "0" + day;
            }
            var date = year + "." + month + "." + day;

            function sprawdzWiek(userDay, userMonth, userYear) {
              var now = new Date(),
                maxAge = 67;
              (month_of_birth = userMonth),
                (day_of_birht = userDay),
                (year_of_birth = userYear),
                (now_month = now.getMonth() + 1),
                (now_day = now.getDate()),
                (now_year = now.getFullYear()),
                (age = now_year - year_of_birth);

              if (now_month.toString().length == 1) {
                now_month = "0" + now_month;
              }

              function validateAge() {
                var ageValid = false;

                if (age == maxAge) {
                  if (now_month >= month_of_birth) {
                    if (now_day <= day_of_birht) {
                      ageValid = true;
                    }
                  }
                } else if (age < maxAge) {
                  ageValid = true;
                }

                return ageValid;
              }

              return validateAge();
            }

            return sprawdzWiek(day, month, year);
          }

          return getDate(pesel);
        },
        'Z przykrością informujemy, że nie możemy zawrzeć umowy na ten produkt. Prosimy o wybranie innego produktu: <a href="javascript:void(0)" onclick="javascript:window.location = \'/zawarcie-umowy-fachowiec\';">Energia + Fachowiec</a> lub <a href="javascript:void(0)" onclick="javascript:window.location = \'/zawarcie-umowy-pewna-cena\';">Pewna Cena</a>"'
      );

      if (selector) {
        var form = $(selector);
        cloneField('input[name="Imie"]', 'input[name="Imie2"]', form);
        cloneField('input[name="Imie"]', 'input[name="Imie3"]', form);
        cloneField('input[name="Nazwisko"]', 'input[name="Nazwisko2"]', form);
        cloneField('input[name="Nazwisko"]', 'input[name="Nazwisko3"]', form);
        cloneField(
          'input[name="Pesel"]',
          'input[name="DataUrodzenia"]',
          form,
          "peselAndAge"
        );
        cloneField(
          'input[name="Pesel"]',
          'input[name="DataUrodzenia2"]',
          form,
          "pesel"
        );
        cloneField('input[name="Pesel"]', 'input[name="Pesel2"]', form);
        cloneField('input[name="Pesel"]', 'input[name="Pesel3"]', form);
        cloneField('input[name="Telefon"]', 'input[name="Telefon2"]', form);
        cloneField('input[name="Telefon"]', 'input[name="Telefon3"]', form);
        cloneField('input[name="Email"]', 'input[name="Email2"]', form);
        cloneField(
          'input[name="DanePpeAdresUlica"]',
          'input[name="DanePpeAdresUlica2"]',
          form
        );
        cloneField(
          'input[name="DanePpeAdresNrDomu"]',
          'input[name="DanePpeAdresNrDomu2"]',
          form
        );
        cloneField(
          'input[name="DanePpeAdresNrMieszkania"]',
          'input[name="DanePpeAdresNrMieszkania2"]',
          form,
          "nrMieszkania"
        );
        cloneField(
          'input[name="DanePpeAdresKodPocztowy"]',
          'input[name="DanePpeAdresKodPocztowy2"]',
          form
        );
        cloneField(
          'input[name="DanePpeAdresMiejscowosc"]',
          'input[name="DanePpeAdresMiejscowosc2"]',
          form
        );
        if (selector == "form#ZamowUmoweOnline") {
          cloneField(
            'input[name="DanePpeAdresKodPocztowy2"]',
            'input[name="DanePpeAdresKodPocztowy"]',
            form
          );
          cloneField(
            'input[name="DanePpeAdresMiejscowosc2"]',
            'input[name="DanePpeAdresMiejscowosc"]',
            form
          );
        }
      }
      function cloneField(fFrom, fTo, form, type) {
        if (!type) {
          form.find(fFrom).on("change", function() {
            form.find(fTo).val($(this).val());
          });
        } else if (type === "pesel") {
          form.find(fFrom).on("change", function() {
            form.find(fTo).val(getDate($(this).val(), false));
          });
        } else if (type === "peselAndAge") {
          form.find(fFrom).on("change", function() {
            form.find(fTo).val(getDate($(this).val(), true));
          });
        } else if (type === "nrMieszkania") {
          form.find(fTo).val("-");

          form.find(fFrom).on("change", function() {
            if ($(this).val() === "") {
              form.find(fTo).val("-");
            } else {
              form.find(fTo).val($(this).val());
            }
          });
        }
      }

      function getDate(pesel, checkAge) {
        var year = parseInt(pesel.substring(0, 2), 10);
        var month = parseInt(pesel.substring(2, 4), 10);
        var day = parseInt(pesel.substring(4, 6), 10);

        if (month > 80) {
          year = year + 1800;
          month = month - 80;
        } else if (month > 60) {
          year = year + 2200;
          month = month - 60;
        } else if (month > 40) {
          year = year + 2100;
          month = month - 40;
        } else if (month > 20) {
          year = year + 2000;
          month = month - 20;
        } else {
          year += 1900;
        }
        if (month.toString().length == 1) {
          month = "0" + month;
        }
        if (day.toString().length == 1) {
          day = "0" + day;
        }
        var date = year + "." + month + "." + day;

        function sprawdzWiek(userDay, userMonth, userYear) {
          var now = new Date(),
            maxAge = 67;
          (month_of_birth = userMonth),
            (day_of_birht = userDay),
            (year_of_birth = userYear),
            (now_month = now.getMonth() + 1),
            (now_day = now.getDate()),
            (now_year = now.getFullYear()),
            (age = now_year - year_of_birth);

          if (now_month.toString().length == 1) {
            now_month = "0" + now_month;
          }

          function validateAge() {
            var ageValid = false;

            if (age == maxAge) {
              if (now_month >= month_of_birth) {
                if (now_day <= day_of_birht) {
                  ageValid = true;
                }
              }
            } else if (age < maxAge) {
              ageValid = true;
            }

            return ageValid;
          }

          if (!validateAge()) {
          }
        }

        //Checking Age (67 years old)
        if (checkAge && $(selector).attr("data-name") == "formularz-zdrowie") {
          sprawdzWiek(day, month, year);
        }

        return date;
      }
    },
    attachmentsList: function() {
      var input = document.querySelector(".uploader input");      

      input.addEventListener("change", function() {
        var filesWrapper = document.querySelector(".uploader .filename");
        if (!filesWrapper) return
        var files = input.files;
        filesWrapper.innerHTML = "";
            
        for (var i = 0; i < files.length; i++) {
          file = files[i];
          filename = file.name;

          var el = document.createElement("span");
          el.classList.add("item");
          el.innerHTML = filename;
          filesWrapper.append(el);
        }
      });
    },
    contactDataAKT: function(selector) {
      var selector = $(selector);
      if (selector) {
        var d = new Date();
        var strDate = d.getFullYear();
        var numerSprawy = "EN/AKT/" + strDate + "/";
        $("#Aktualizacja_danych_klienta")
          .find('input[name="CONFIG_NUMER_SPRAWY"]')
          .css({ "padding-left": "188px" })
          .attr("placeholder", "tu wpisz kod sprawy")
          .before(
            '<span style="position: absolute; font-size: 26px; font-weight: bold; padding-top: 6px; padding-left: 14px;">' +
              numerSprawy +
              "</span>"
          );
      }
    },
    tilesTabs: (function(selector) {
      $(window)
        .resize(function() {
          if ($(window).width() < 768) {
            var tabs_content = $(".offer-tabs__tab");
            tabs_content.each(function() {
              var id = $(this).attr("id");
              var dist = $('a[href="#' + id + '"]').closest(
                ".offer-tabs__nav__elem"
              );
              $(this).insertAfter(dist);
            });
          } else {
            var tabs_content = $(".offer-tabs__tab");
            tabs_content.each(function() {
              var dist = $(".offer-tabs .general-tabs__content");
              $(this).appendTo(dist);
            });
          }
        })
        .trigger("resize");
    })(),
    generalTabs: function(selector) {
      $(".general-tabs__nav a").click(function(e) {
        e.preventDefault();
        var parent = $(this).closest(".general-tabs");
        parent.find(".general-tabs__nav__elem").removeClass("active");
        $(this)
          .parents(".general-tabs__nav__elem")
          .addClass("active");
        var target = $(this).attr("href");
        parent.find(".general-tabs__tab").removeClass("active");
        parent.find(target).addClass("active");
      });

      $(window)
        .resize(function() {
          if ($(window).width() < 768) {
            $(".general-tabs").each(function() {
              if (!$(this).hasClass("price-list-tabs__subtabs")) {
                var parent = $(this);
                if ($(".general-tabs__content").length) {
                  parent
                    .find(".general-tabs__content:not(.test)")
                    .children(".general-tabs__tab")
                    .each(function(i, el) {
                      var content = "#" + $(this).attr("id");
                      parent
                        .find('.general-tabs__nav a[href="' + content + '"]')
                        .parents(".general-tabs__nav__elem")
                        .after($(this));
                    });
                } else {
                  parent.children(".general-tabs__tab").each(function(i, el) {
                    var content = "#" + $(this).attr("id");
                    parent
                      .find('.general-tabs__nav a[href="' + content + '"]')
                      .parents(".general-tabs__nav__elem")
                      .after($(this));
                  });
                }
              }
            });
          } else {
            $(".general-tabs").each(function() {
              var parent = $(this);
              parent.children(".general-tabs__tab").each(function() {
                parent.append($(this));
              });
            });
          }
        })
        .trigger("resize");
    },
    priceTabs: function(selector) {
      $(".container--price-list .price-tabs__nav__elem a").click(function(e) {
        e.preventDefault();
        var parent = $(this).closest(".price-tabs");
        parent.find(".price-tabs__nav__elem").removeClass("active");
        $(this)
          .parents(".price-tabs__nav__elem")
          .addClass("active");
        var target = $(this).attr("href");
        parent.find(".price-tabs__tab").removeClass("active");
        parent.find(target).addClass("active");
        if ($(window).width() >= 768) {
          $(window).scrollTo(this, 100, {
            offset: {
              top: -170
            }
          });
        } else {
          $(window).scrollTo(this, 100, {
            offset: {
              top: 0
            }
          });
        }
      });
      // $('.container--price-list .price-subtabs__nav__elem a').click(function(e) {
      //     e.preventDefault();
      //     var parent = $(this).closest('.price-subtabs');
      //     parent.find('.price-subtabs__nav__elem').removeClass('active');
      //     $(this).parents('.price-subtabs__nav__elem').addClass('active');
      //     var target = $(this).attr('href');
      //     parent.find('.price-subtabs__subtab').removeClass('active');
      //     parent.find(target).addClass('active');
      // })
    },
    subTabs: function(selector) {
      $(".subtabs__nav__elem a").click(function(e) {
        e.preventDefault();
        var parent = $(this).closest(".subtabs");
        parent.find(".subtabs__nav__elem").removeClass("active");
        $(this)
          .parents(".subtabs__nav__elem")
          .addClass("active");
        var target = $(this).attr("href");
        parent.find(".subtabs__subtab").removeClass("active");
        parent.find(target).addClass("active");
      });
    },
    openFixedForm: function(selector) {
      $(".fixed-form__trigger-button").click(function(e) {
        e.preventDefault();
        $(".fixed-form").toggleClass("active");
      });
      $(document).mouseup(function(e) {
        var container = $(".fixed-form");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $(".fixed-form").removeClass("active");
        }
      });
    },
    generalAccordion: function(selector) {
      var expandedAll = false;

      var hash = window.location.hash;
      var accordeonAnchors = $(".general-accordion__panel");
      accordeonAnchors.each(function() {
        var accordeonHash = "#" + $(this).attr("id");
        if (accordeonHash == hash) {
          $(".general-accordion__panel").removeClass("open");
          $(this).addClass("open");
          $(window).trigger("resize");
          $(window).scrollTo(this, 100);
        }
      });

      $(".anchor-accordeon").on("click", function(e) {
        var hash = $(this)
          .attr("href")
          .split("#")[1];
        var link = $(this);
        hash = "#" + hash;
        var accordeonAnchors = $(".general-accordion__panel");

        accordeonAnchors.each(function() {
          var accordeonHash = "#" + $(this).attr("id");
          if (accordeonHash == hash) {
            if (!expandedAll) {
              link.closest(".general-accordion__panel").removeClass("open");
            }
            $(this).addClass("open");
            $(window).trigger("resize");
            $(window).scrollTo(this, 100);
          }
        });
      });

      $(
        ".general-accordion h4, .general-accordion__trigger, .general-accordion__panel-subtitle"
      ).click(function(e) {
        e.preventDefault();
        if (
          $(this)
            .parents(".general-accordion__panel")
            .hasClass("open")
        ) {
          if (expandedAll) {
            $(this)
              .closest(".general-accordion__panel")
              .removeClass("open");
            if ($(".general-accordion__panel.open").length === 0) {
              $(".accordion-group__expander").text("Rozwiń wszystkie");
              expandedAll = !expandedAll;
            }
          } else {
            $(".general-accordion__panel").removeClass("open");
          }
        } else {
          if (expandedAll) {
            $(this)
              .closest(".general-accordion__panel")
              .addClass("open");
          } else {
            $(".general-accordion__panel").removeClass("open");
            $(this)
              .parents(".general-accordion__panel")
              .toggleClass("open");
            setTimeout(function() {
              $(window).scrollTo($(".general-accordion__panel.open"), 200, {
                offset: {
                  top: -150
                }
              });
            }, 800);
          }
        }
      });
      $(".accordion-group__expander").on("click", function() {
        if (expandedAll) {
          $(this).text("Rozwiń wszystkie");
          $(".general-accordion__panel").removeClass("open");
        } else {
          $(this).text("Zwiń wszystkie");
          $(".general-accordion__panel").addClass("open");
        }
        expandedAll = !expandedAll;
      });
    },
    func: function(fnName, selector, argArray) {
      if (typeof selector === "undefined") {
        selector = "body";
      }

      if (typeof argArray === "undefined") {
        argArray = [];
      }

      argArray.unshift(selector);
      if (typeof ENEA[fnName] === "function" && $(selector).length > 0) {
        ENEA[fnName].apply(this, argArray);
      }
    }
  });

  ENEA.init();
});

var cookies = (function() {
  var read = function(name) {
      if (document.cookie != "") {
        var cookies = document.cookie.split("; ");
        for (i = 0; i < cookies.length; i++) {
          var nameCookie = cookies[i].split("=")[0];
          var valueCookie = cookies[i].split("=")[1];
          if (nameCookie === name) {
            return unescape(valueCookie);
          }
        }
      }
    },
    set = function(name, value, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();

      document.cookie = name + "=" + value + "; " + expires + "; path=/";
    };

  return {
    set: set,
    read: read
  };
})();

var addons = (function($) {
  var setSlug = function(string) {
    var str = string;
    var nStr = str.replace(/(^\s+|\s+$)/g, "");
    return nStr
      .toLowerCase()
      .replace(/ - /g, "-")
      .replace(/ą/g, "a")
      .replace(/ć/g, "c")
      .replace(/ę/g, "e")
      .replace(/ł/g, "l")
      .replace(/ń/g, "n")
      .replace(/ó/g, "o")
      .replace(/ś/g, "s")
      .replace(/ż/g, "z")
      .replace(/ +$/, "")
      .replace(/ +/g, "-");
  };
  return {
    setSlug: setSlug
  };
})();

jQuery(document).ready(function($) {
  //function GetIEVersion() {
  //    var sAgent = window.navigator.userAgent;
  //    var Idx = sAgent.indexOf("MSIE");
  //
  //    if (Idx > 0){
  //        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf(".", Idx)));
  //
  //    }else if (!!navigator.userAgent.match(/Trident\/7\./)){
  //        return 11;
  //
  //    }else{
  //        return 0; //It is not IE
  //    }
  //}
  //
  //var ieVersion = GetIEVersion();
  //
  //if (ieVersion == 8 || ieVersion == 7) {
  //
  //    function setCookie(key, value, days) {
  //        if (days) {
  //            var data = new Date();
  //            data.setTime(data.getTime()+(days*24*60*60*1000));
  //            var expires = "; expires="+data.toGMTString();
  //        } else {
  //            var expires = "";
  //        }
  //        document.cookie = key+"=" + value + expires + "; path=/";
  //    }
  //
  //    function getCookie(key) {
  //        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
  //        return keyValue ? keyValue[2] : null;
  //    }
  //
  //    var ieCookie = getCookie('changeBrowser');
  //
  //    if( ieCookie == null ){
  //        setCookie('changeBrowser', true, 3);
  //        $('.ie-support-window').removeClass('hide').addClass('show');
  //    }
  //
  //}
  //
  ////Closing IESupport window
  //$('.close-ie-info').on('click', function(){
  //    $('.ie-support-window').removeClass('show').addClass('hide');
  //})

  var datePickerFromTo = function(from, to) {
    var datePickerFrom = $(from);
    var datePickerTo = $(to);

    $.datepicker.regional["pl"] = {
      closeText: "Zamknij",
      prevText: "Poprzedni",
      nextText: "Następny",
      currentText: "Dzisiaj",
      monthNames: [
        "Styczeń",
        "Luty",
        "Marzec",
        "Kwiecień",
        "Maj",
        "Czerwiec",
        "Lipiec",
        "Sierpień",
        "Wrzesień",
        "Październik",
        "Listopad",
        "Grudzień"
      ],
      monthNamesShort: [
        "Sty",
        "Lu",
        "Mar",
        "Kw",
        "Maj",
        "Cze",
        "Lip",
        "Sie",
        "Wrz",
        "Pa",
        "Lis",
        "Gru"
      ],
      dayNames: [
        "Niedziela",
        "Poniedziałek",
        "Wtorek",
        "Środa",
        "Czwartek",
        "Piątek",
        "Sobota"
      ],
      dayNamesShort: ["Nie", "Pn", "Wt", "Śr", "Czw", "Pt", "So"],
      dayNamesMin: ["N", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
      weekHeader: "Tydz",
      dateFormat: "yy-mm-dd",
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: "",
      showOn: "button",
      buttonImage: "/static/img/basic/ico-calendar-blue.png",
      buttonImageOnly: true,
      buttonText: "Wybierz datę"
    };

    $.datepicker.setDefaults($.datepicker.regional["pl"]);

    datePickerFrom.datepicker({
      numberOfMonths: 1,
      onClose: function(selectedDate) {
        $(datePickerTo).datepicker("option", "minDate", selectedDate);
      }
    });
    datePickerTo.datepicker({
      numberOfMonths: 1,
      onClose: function(selectedDate) {
        $(datePickerFrom).datepicker("option", "maxDate", selectedDate);
      }
    });
  };

  datePickerFromTo("#calendarFrom", "#calendarTo");
});

function closeOverlay(e) {
  e.preventDefault();
  $(event.target)
    .closest(".lot-overlay__error")
    .removeClass("active");
  $(event.target)
    .closest(".lot-overlay")
    .removeClass("active");
  $(event.target)
    .closest(".overlayed")
    .removeClass("overlayed");
}

function UKformHandler(form) {

  var overlays =
    '<div class="lot-overlay"><div class="lot-overlay__success"><div class="lot-overlay__success-inner"><div class="lot-overlay__success-ico"></div><h4 class="lot-overlay__success-header">Dziękujemy za wypełnienie formularza!</h4><p class="lot-overlay__success-info">Nasz konsultant wkrótce się z Tobą skontaktuje.</p><p class="lot-overlay__success-info">ENEA S.A.</p></div></div><div class="lot-overlay__info"><div class="lot-overlay__info-inner"><h4 class="lot-overlay__info-header">Akcja nie jest już aktywna</h4><p class="lot-overlay__info-info">Zapraszamy do udziału w innych akcjach.</p><p class="lot-overlay__info-info">Zespół ENEA</p></div></div><div class="lot-overlay__loading"><div class="lot-overlay__loading-inner"><div class="lot-overlay__loading-ico"></div></div></div><div class="lot-overlay__error"><div class="lot-overlay__error-inner"><h4 class="lot-overlay__error-header">Wystąpił problem z wysłaniem formularza.</h4><p class="lot-overlay__error-info">Podane dane są niepoprawne. Sprawdź wprowadzone dane i wyślij ponownie formularz.</p><a href="#" class="lot-overlay__error-button button button--arrow button--filled" onClick="closeOverlay(event)">Spróbuj ponownie</a></div></div></div>';

  var overlayPromo =
    '<div class="lot-overlay"><div class="lot-overlay__success"><div class="lot-overlay__success-inner"><div class="lot-overlay__success-ico"></div><h4 class="lot-overlay__success-header">Dziękujemy za wypełnienie formularza!</h4><p class="lot-overlay__success-info">Nasz konsultant wkrótce się z Tobą skontaktuje.</p><p class="lot-overlay__success-info">ENEA S.A.</p></div></div><div class="lot-overlay__info"><div class="lot-overlay__info-inner"><h4 class="lot-overlay__info-header">Akcja nie jest już aktywna</h4><p class="lot-overlay__info-info">Zapraszamy do udziału w innych akcjach.</p><p class="lot-overlay__info-info">Zespół ENEA</p></div></div><div class="lot-overlay__loading"><div class="lot-overlay__loading-inner"><div class="lot-overlay__loading-ico"></div></div></div><div class="lot-overlay__error"><div class="lot-overlay__error-inner"><h4 class="lot-overlay__error-header">Wystąpił problem z wysłaniem formularza.</h4><p class="lot-overlay__error-info">Podane dane są niepoprawne. Sprawdź wprowadzone dane i wyślij ponownie formularz.</p><a href="#" class="lot-overlay__error-button button button--arrow button--filled" onClick="closeOverlay(event)">Spróbuj ponownie</a></div></div></div>';

  var lotery = false;
  var injectIntoContainer = false;

  if ($(form).attr("lotery") == "true") {
    lotery = true;
  }

  if ($(form).attr("inject-into-container") == "true") {
    injectIntoContainer = true;
  }

  if (
    $(form)
      .closest(".lot-overlay-container")
      .find(".lot-overlay").length === 0 &&
    injectIntoContainer
  ) {
    if (form.id === "SummerPromotion") {
      $(form)
        .closest(".lot-overlay-container")
        .prepend(overlayPromo);
    } else {
      $(form)
        .closest(".lot-overlay-container")
        .prepend(overlays);
    }
  } else if (
    ($(form).find(".lot-overlay").length === 0 && !lotery) ||
    ($(form)
      .closest(".lot-overlay-container")
      .find(".lot-overlay").length === 0 &&
      lotery)
  ) {
    if (lotery) {
      if (form.id === "SummerPromotion") {
        $(form)
          .closest(".lot-overlay-container")
          .prepend(overlayPromo);
      } else {
        $(form)
          .closest(".lot-overlay-container")
          .prepend(overlays);
      }
    } else {
      if (form.id === "SummerPromotion") {
        $(form).prepend(overlayPromo);
      } else {
        $(form).prepend(overlays);
      }
    }
  }

  if ($(form).find(".g-recaptcha").length > 0) {
    var response =
      $(".g-recaptcha").length === 1
        ? grecaptcha.getResponse()
        : grecaptcha.getResponse(
            $(form)
              .find(".g-recaptcha")
              .attr("data-widget-id")
          );
  }

  if ($(form).find(".g-recaptcha").length > 0 && response == "") {
    $(form)
      .find(".recaptcha-error")
      .html("Proszę uzupełnić captchę.");
  } else if (
    $('input[data-email-base="true"]').length > 0 &&
    $('input[data-email-repeat="true"]').length > 0 &&
    $('input[data-email-base="true"]')
      .val()
      .toLowerCase() !==
      $('input[data-email-repeat="true"]')
        .val()
        .toLowerCase()
  ) {
    var id = $('input[data-email-repeat="true"]').attr("id");
    $('input[data-email-repeat="true"]')
      .parent()
      .append(
        '<div id="' +
          id +
          '-error" class="error" style="display:block">Niepoprawny adres email.</div>'
      );
    $('input[data-email-repeat="true"]')
      .addClass("error")
      .attr("aria-invalid", "true")
      .attr("aria-describedby", id + "-error");
  } else if ($(form).valid()) {
    if (lotery || injectIntoContainer) {
      $(form)
        .closest(".lot-overlay-container")
        .find(".lot-overlay")
        .addClass("active");
      $(form)
        .closest("lot-overlay-container")
        .find(".lot-overlay__loading")
        .addClass("active");
      $(form)
        .closest("lot-overlay-container")
        .addClass("overlayed");
    } else {
      $(form)
        .find(".lot-overlay")
        .addClass("active");
      $(form)
        .find(".lot-overlay__loading")
        .addClass("active");
    }
    var data = {};
    $(form)
      .find("input, textarea")
      .each(function() {
        var val = "";
        if ($(this).attr("type") === "checkbox") {
          val = $(this).is(":checked") ? 1 : 0;
        } else {
          val = $(this).val();
        }
        data[$(this).attr("name")] = val;
      });
    if ($(form).attr("ukcontactform") == "true") {
      var data = new FormData($(form)[0]);
    }
    $.ajax({
      url: $(form).attr("action"),
      method: "POST",
      timeout: 20000,
      data: data,
      mimeType: "multipart/form-data",
      contentType: false,
      cache: false,
      processData: false
    })
      .done(function(response) {
        var res = JSON.parse(response);
        if (res.status === "error") {
          if (lotery || injectIntoContainer) {
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__loading")
              .removeClass("active");
            if (res.msg !== undefined) {
              $(form)
                .closest(".lot-overlay-container")
                .find(".lot-overlay__error-inner")
                .html(res.msg);
            }
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__error")
              .addClass("active");
          } else {
            $(form)
              .find(".lot-overlay__loading")
              .removeClass("active");
            if (res.msg !== undefined) {
              $(form)
                .find(".lot-overlay__error-inner")
                .html(res.msg);
            }
            $(form)
              .find(".lot-overlay__error")
              .addClass("active");
          }
        } else if (res.status === "ok") {
          if (lotery || injectIntoContainer) {
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__loading")
              .removeClass("active");
            if (res.msg !== undefined) {
              $(form)
                .closest(".lot-overlay-container")
                .find(".lot-overlay__success-inner")
                .html(res.msg);
            }
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__success")
              .addClass("active");
          } else {
            $(form)
              .find(".lot-overlay__loading")
              .removeClass("active");

            if (form.id === "SummerPromotion") {
              var promores =
                '<div class="lot-overlay__success-ico"></div><h4 class="lot-overlay__success-header">Dziękujemy<br><span>za wypełnienie formularza!</span></h4><p class="lot-overlay__success-info">Nasz konsultant wkrótce się z Tobą skontaktuje.</p><p class="lot-overlay__success-info">ENEA S.A.</p>';

              $(form)
                .find(".lot-overlay__success-inner")
                .html(promores);
            } else {
              $(form)
                .find(".lot-overlay__success-inner")
                .html(res.msg);
            }
            $(form)
              .find(".lot-overlay__success")
              .addClass("active");
          }
        } else if (res.status === "info") {
          if (lotery || injectIntoContainer) {
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__loading")
              .removeClass("active");
            if (res.msg !== undefined) {
              $(form)
                .closest(".lot-overlay-container")
                .find(".lot-overlay__info-inner")
                .html(res.msg);
            }
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__info")
              .addClass("active");
          } else {
            $(form)
              .find(".lot-overlay__loading")
              .removeClass("active");
            if (res.msg !== undefined) {
              $(form)
                .find(".lot-overlay__info-inner")
                .html(res.msg);
            }
            $(form)
              .find(".lot-overlay__info")
              .addClass("active");
          }
        } else if (res.status === "validate") {
          if (lotery || injectIntoContainer) {
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__loading")
              .removeClass("active");
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay")
              .removeClass("active");
            $(form)
              .closest(".lot-overlay-container")
              .removeClass("overlayed");
            $(form)
              .find("input")
              .each(function() {
                var id = $(this).attr("id");
                var name = $(this).attr("name");
                if (res.msg !== undefined && res.msg[name] !== undefined) {
                  if (
                    $(this)
                      .parent()
                      .find("#" + id + "-error").length === 0
                  ) {
                    $(this)
                      .parent()
                      .append(
                        '<div id="' +
                          id +
                          '-error" class="error" style="display:block">Błąd walidacji</div>'
                      );
                    $(this)
                      .addClass("error")
                      .attr("aria-invalid", "true")
                      .attr("aria-describedby", id + "-error");
                  } else {
                    $(this)
                      .parent()
                      .find("#" + id + "-error")
                      .html(res.validate[name])
                      .css("display", "block");
                    $(this)
                      .addClass("error")
                      .attr("aria-invalid", "true")
                      .attr("aria-describedby", id + "-error");
                  }
                }
              });
          } else {
            $(form)
              .find(".lot-overlay__loading")
              .removeClass("active");
            $(form)
              .find(".lot-overlay")
              .removeClass("active");
            $(form)
              .find("input")
              .each(function() {
                var name = $(this).attr("name");
                var id = $(this).attr("id");
                if (res.msg !== undefined && res.msg[name] !== undefined) {
                  if (
                    $(this)
                      .parent()
                      .find("#" + id + "-error").length === 0
                  ) {
                    $(this)
                      .parent()
                      .append(
                        '<div id="' +
                          id +
                          '-error" class="error" style="display:block">Błąd walidacji</div>'
                      );
                    $(this)
                      .addClass("error")
                      .attr("aria-invalid", "true")
                      .attr("aria-describedby", id + "-error");
                  } else {
                    $(this)
                      .parent()
                      .find("#" + id + "-error")
                      .html(res.validate[name])
                      .css("display", "block");
                    $(this)
                      .addClass("error")
                      .attr("aria-invalid", "true")
                      .attr("aria-describedby", id + "-error");
                  }
                }
              });
          }
        } else {
          if (lotery || injectIntoContainer) {
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__loading")
              .overlay__errorremoveClass("active");
            $(form)
              .closest(".lot-overlay-container")
              .find(".lot-overlay__error")
              .addClass("active");
          } else {
            $(form)
              .find(".lot-overlay__loading")
              .removeClass("active");
            $(form)
              .find(".lot-overlay__error")
              .addClass("active");
          }
        }
      })
      .fail(function(response, textstatus) {

        if (lotery || injectIntoContainer) {
          $(form)
            .closest(".lot-overlay-container")
            .find(".lot-overlay__loading")
            .removeClass("active");
          $(form)
            .closest(".lot-overlay-container")
            .find(".lot-overlay__error")
            .addClass("active");
        } else {
          $(form)
            .find(".lot-overlay__loading")
            .removeClass("active");
          $(form)
            .find(".lot-overlay__error")
            .addClass("active");

          if (response.responseText !== undefined) {
            const res = JSON.parse(response.responseText);
            if (res.force !== undefined && res.force === true && typeof res.msg === 'object') {
              const inputName = Object.keys(res.msg)[0];
              if (typeof inputName === 'string'
                  && inputName.length > 0
                  && typeof res.msg[inputName] === 'object'
              ) {
                const invalidType = Object.keys(res.msg[inputName])[0];
                if (typeof invalidType === 'string'
                    && invalidType.length > 0
                    && typeof res.msg[inputName][invalidType] === 'string'
                ) {
                  const message = res.msg[inputName][invalidType];
                  $(form).find('.lot-overlay__error-info').html(message);
                }
              }
            }
          }
        }
      });
  }
}

function multiStepFormHandler(form) {
  var multiStepForm = $(form);
  var result = $(form).find(".multistep-form__step--result");
  multiStepForm.slick(
    "slickGoTo",
    multiStepForm.slick("slickCurrentSlide") + 1,
    true
  );
  var allValid = true;
  var slide = Infinity;
  $(form)
    .find("input, textarea, select")
    .each(function() {
      if (
        !$(this).closest(
          ".simple-form__option:not(.simple-form__option--active)"
        ).length > 0
      ) {
        if (!$(this).valid()) {
          allValid = false;
          var inputStep = $(this)
            .closest(".slick-slide")
            .attr("data-slick-index");
          if (inputStep >= 0 && inputStep < slide) {
            slide = inputStep;
          }
        }
      }
    });
  if (!allValid) {
    multiStepForm.slick("slickGoTo", slide);
    return;
  }

  if (allValid) {
    var data = {};
    var counters = {};
    $(form)
      .find("input, textarea, select")
      .each(function() {
        var val = "";
        if ($(this).attr("type") === "checkbox") {
          var attr = $(this).attr("value");
          // For some browsers, `attr` is undefined; for others, `attr` is false. Check for both.
          if (typeof attr !== typeof undefined && attr !== false) {
            val = $(this).is(":checked") ? attr : 0;
          } else {
            val = $(this).is(":checked") ? 1 : 0;
          }
        } else if ($(this).attr("type") === "radio") {
          var name = $(this).attr("name");
          val = $('input[name="' + name + '"]:checked').val();
        } else {
          val = $(this).val();
        }

        if (
          !$(this).closest(
            ".simple-form__option:not(.simple-form__option--active)"
          ).length > 0
        ) {
          var name = $(this).attr("name");
          if (name != undefined && name.length > 2 && name.substr(-2) == "[]") {
            if (val !== 0) {
              var shortName = name.substr(0, name.length - 2);
              if (counters[shortName] == undefined) {
                counters[shortName] = 0;
              } else {
                counters[shortName] = counters[shortName] + 1;
              }
              data["" + shortName + "[" + counters[shortName] + "]"] = val;
            }
          } else {
            data[name] = val;
          }
        }
      });

    var dataSend = new FormData($(form)[0]);

    $.ajax({
      url: $(form).attr("action"),
      method: "POST",
      timeout: 20000,
      data: dataSend,
      mimeType: "multipart/form-data",
      contentType: false,
      cache: false,
      processData: false
    })
      .done(function(response) {
        try {
          var res = JSON.parse(response);
        } catch (e) {}
        result.find(".simple-result").removeClass("simple-result--active");
        if (res.status === "error") {
          result
            .find(".simple-result--error")
            .addClass("simple-result--active");
          multiStepForm.slick("slickNext");
        } else if (res.status === "ok") {
          result
            .find(".simple-result--success")
            .addClass("simple-result--active");
          multiStepForm.slick("slickNext");
        } else if (res.status === "info") {
          result.find(".simple-result--info").addClass("simple-result--active");
          multiStepForm.slick("slickNext");
        } else if (res.status === "validate") {
          var slide = Infinity;
          $(form)
            .find("input, select, textarea")
            .each(function() {
              var id = $(this).attr("id");
              var name = $(this).attr("name");
              if (res.msg !== undefined && res.msg[name] !== undefined) {
                var inputStep = $(this)
                  .closest(".slick-slide")
                  .attr("data-slick-index");
                if (inputStep >= 0 && inputStep < slide) {
                  slide = inputStep;
                }
                if (
                  $(this)
                    .parent()
                    .find("#" + id + "-error").length === 0
                ) {
                  if (
                    $(this)
                      .closest(".multistep-form__step")
                      .find('input[name="' + name + '"]')
                      .parent()
                      .find("div.error").length == 0
                  ) {
                    $(this)
                      .parent()
                      .append(
                        '<div id="' +
                          id +
                          '-error" class="error" style="display:block">Błąd walidacji</div>'
                      );
                  }
                } else {
                  $(this)
                    .parent()
                    .find("#" + id + "-error")
                    .html("Błąd walidacji")
                    .css("display", "block");
                }
                $(this)
                  .addClass("error")
                  .attr("aria-invalid", "true")
                  .attr("aria-describedby", id + "-error");
              }
            });
          multiStepForm.slick("slickGoTo", slide);
        } else {
          result
            .find(".simple-result--error")
            .addClass("simple-result--active");
          multiStepForm.slick("slickNext");
        }
      })
      .fail(function(response, textstatus) {
        result.find(".simple-result").removeClass("simple-result--active");
        result.find(".simple-result--error").addClass("simple-result--active");
        multiStepForm.slick("slickNext");
      });
  }
}

function getCoords(elem) {
  // crossbrowser version
  var box = elem.getBoundingClientRect();

  var body = document.body;
  var docEl = document.documentElement;

  var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  var clientTop = docEl.clientTop || body.clientTop || 0;
  var clientLeft = docEl.clientLeft || body.clientLeft || 0;

  var top = box.top + scrollTop - clientTop;
  var left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}
